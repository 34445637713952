import { cyan, green, grey, orange, red } from '@material-ui/core/colors'
import { alpha as fade } from '@material-ui/core/styles'
import Colors from '../../Colors'
import { cacheStatusColors, webVitalsColors } from '../customColors'

export const custom = {
  palette: {
    brand: {
      primary: grey[800],
      secondary: '#DD549F',
      contrastText: grey[50]
    },
    danger: {
      main: red['A400'],
      light: red[300],
      dark: red[700],
      contrastText: '#fff'
    },
    cacheStatus: cacheStatusColors,
    webVitals: webVitalsColors,
    codeBackground: {
      dark: {
        shady: fade(grey[700], 0.2)
      },
      danger: {
        dark: red[900],
        shady: fade(red[900], 0.7)
      },
      success: {
        dark: green[900]
      },
      warning: {
        dark: orange[900]
      },
      link: {
        highlight: fade(cyan[700], 0.2)
      }
    }
  },
  typography: {
    captionContrast: {
      color: Colors.White,
      lineHeight: 1.33
    },
    code: {
      fontFamily: "Menlo, Monaco, 'Courier New', monospace", // Here we use the font from VS Code because it's the most popular editor.  One day this comment will be horribly outdated.
      fontSize: 12
    }
  }
}
