import { defaults } from 'react-chartjs-2'
import { grey } from '@material-ui/core/colors'
import { createTheme as createMuiTheme } from '@material-ui/core/styles'
import merge from 'ts-deepmerge'
import darkThemeConfig from './dark'
import lightThemeConfig from './light'
import { overrides } from './themeOverrides'
import { Theme } from './themeTypes'

export function createTheme(dark: boolean) {
  const config = dark ? darkThemeConfig : lightThemeConfig

  const theme = createMuiTheme(config as {})

  const themeWithOverrides = merge(theme, {
    overrides: overrides(theme as Theme)
  })

  defaults.global.defaultFontColor = dark ? theme.palette.primary.contrastText : grey[800]

  return themeWithOverrides
}
