import React from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '../../../../theme'
import { drawerWidth, drawerWidthXS } from '../constants'
import { TDrawer, TDrawerHeader } from '../types'
import { DrawerHeader } from './DrawerHeader'

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,

    [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
      width: drawerWidthXS
    }
  },
  drawerContent: {
    flexGrow: 1,
    padding: theme.spacing(2, 2.5)
  }
}))

export const Drawer = ({
  title,
  anchor,
  toggleDrawer,
  isOpen,
  children,
  onClose,
  footer = null
}: TDrawer & TDrawerHeader) => {
  const classes = useStyles()

  return (
    <React.Fragment key={anchor}>
      <SwipeableDrawer
        anchor={anchor}
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerHeader title={title} onClose={onClose} />
        <div className={classes.drawerContent}>{children}</div>
        {footer}
      </SwipeableDrawer>
    </React.Fragment>
  )
}
