import React, { MouseEvent } from 'react'
import MuiChip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import { AutocompleteGetTagProps } from '@material-ui/lab'
import { ClearButton } from './ClearButton'

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: 'currentcolor',
    borderRadius: '0.375rem',
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    height: '1.6875rem'
  },
  label: {
    paddingLeft: 0
  },
  sizeSmall: {
    height: '1.3125rem'
  }
}))

export type TagProps = AutocompleteGetTagProps & {
  onDelete: (event: MouseEvent) => void
}

interface Props {
  label: string
  large?: boolean
  tagProps: TagProps
}

export function Chip({ label, large = false, tagProps }: Props) {
  const classes = useStyles()

  return (
    <MuiChip
      classes={classes}
      deleteIcon={<></>}
      icon={<ClearButton onClick={tagProps.onDelete} size="small" />}
      label={label}
      size={large ? 'medium' : 'small'}
      variant="outlined"
      {...tagProps}
    />
  )
}
