import { ApolloClient, ApolloLink, InMemoryCache, from } from '@apollo/client'

/* istanbul ignore file */
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { get } from 'lodash'

const getCable = async () => {
  const ActionCable = (await import('actioncable')).default
  return ActionCable.createConsumer(`${process.env.WS_PROTOCOL}://${process.env.API_URL}/cable`)
}

let cable

export default async function createApolloClient() {
  if (!cable) {
    cable = await getCable()
  }

  let onUnauthorizedHook = () => {}
  let onInternalErrorHook = () => {}
  let csrfToken = null
  let getCsrfTokenPromise = null

  // As multiple graphql queries can fail simulatenously, we make sure that the
  // consequent JWT queries are de-duplicated
  const getCsrfTokenOnce = async () => {
    if (!getCsrfTokenPromise) {
      getCsrfTokenPromise = getCsrfToken().finally(() => {
        getCsrfTokenPromise = null
      })
    }
    return getCsrfTokenPromise
  }

  const getCsrfToken = async () => {
    const response = await fetch(`${process.env.PROTOCOL}://${process.env.API_URL}/api/csrf`, {
      credentials: 'include'
    })
    if (response.status !== 200) {
      return null
    }
    const { token } = await response.json()
    return token
  }

  const hasSubscriptionOperation = ({ query: { definitions } }) => {
    return definitions.some(
      ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription'
    )
  }

  const linkOptions = {
    uri: `${process.env.PROTOCOL}://${process.env.API_URL}/graphql`,
    credentials: 'include',
    headers: {
      accept: 'application/json'
    }
  }

  const fetchcsrfToken = setContext(async (_, { headers }) => {
    // if you have a cached value, return it immediately
    if (!csrfToken) {
      csrfToken = await getCsrfTokenOnce()
    }

    return {
      headers: {
        ...headers,
        'X-CSRF-Token': csrfToken
      }
    }
  })

  const errorHandlerLink = onError(({ forward, operation, networkError, graphQLErrors }) => {
    const SESSION_ENDED_MSG = 'Session has ended!'
    const INTERNAL_ERROR_MESSAGE = 'Something went wrong, please try again later!'

    // 422 happens when session times out on the backend and the CSRF token
    // does not match with the new session CSRF token
    // We clear the token and retry, which should fetch a new one
    if (networkError?.statusCode === 422) {
      csrfToken = null
      return forward(operation)
    }
    // Trigger logout callback when devise returns 401
    if (networkError?.statusCode === 401) {
      onUnauthorizedHook(get(networkError, 'result.error', SESSION_ENDED_MSG))
    }
    // Show maintenance error and internal errors
    // Backend should not send internal error details in production mode
    if (networkError?.statusCode >= 500) {
      onInternalErrorHook(get(networkError, 'result.error', INTERNAL_ERROR_MESSAGE))
    }
    if (
      networkError?.statusCode === 503 &&
      // TODO: first condition can go away once https://github.com/moovweb/le-deployer/pull/1456 goes live
      (get(networkError, 'error') === 'Layer0 Console in under maintenance' ||
        get(networkError, 'code') === 'CONSOLE_MAINTENANCE')
    ) {
      if (window.location.pathname !== '/maintenance') {
        window.location = `/maintenance?redirectTo=${encodeURIComponent(window.location.pathname)}`
      }
    }
    // Any other response containing a first-level 'errors' attribute is an internal
    // error too
    if (graphQLErrors) {
      onInternalErrorHook(graphQLErrors[0].message)
    }
  })

  const transportLink = ApolloLink.split(
    hasSubscriptionOperation,
    new ActionCableLink({ cable }),
    createUploadLink(linkOptions)
  )

  const client = new ApolloClient({
    cache: new InMemoryCache({
      // addTypename: false
    }),
    link: from([errorHandlerLink, fetchcsrfToken, transportLink])
  })

  client.onUnauthorized = (fn) => {
    onUnauthorizedHook = fn
  }

  client.onInternalError = (fn) => {
    onInternalErrorHook = fn
  }

  return client
}
