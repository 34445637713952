import { Accordion as MuiAccordion } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const Accordion = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.type === 'dark' ? '#101820' : '#ECEDEE'}`,
    boxShadow: '0px 0px 10px rgba(21, 31, 41, 0.1)',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',

    '&:not(:last-child)': {
      marginBottom: theme.spacing(3)
    },

    '&:before': {
      display: 'none'
    },

    '&$expanded': {
      marginBottom: theme.spacing(3)
    }
  },
  expanded: {}
}))(MuiAccordion)
