import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '../../../../Typography'
import { Theme } from '../../../../theme'
import { IconButton } from '../../../atoms'
import { TDrawerHeader } from '../types'

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2.5),
    // if necessary for content to be below app bar - uncomment below line
    // ...theme.mixins.toolbar,
    minHeight: 'auto'
  }
}))

export const DrawerHeader = ({ title, onClose }: TDrawerHeader) => {
  const { toolbar } = useStyles()

  return (
    <Toolbar className={toolbar}>
      <Typography variant="body2">{title}</Typography>
      <IconButton size="small" onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Toolbar>
  )
}
