/**
 * Moves array element from source to destination
 * @param {any[]} arr Array to manipulate
 * @param {number} source Source index
 * @param {number} destination Destination index
 */
export default function moveArrayElement(arr, source, destination) {
  const arrClone = Object.assign([], arr)
  const sourceElement = arr[source]
  arrClone.splice(source, 1)
  arrClone.splice(destination, 0, sourceElement)
  return arrClone
}
