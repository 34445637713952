import merge from 'ts-deepmerge'
import { baseThemeConfig } from '../base'
import { custom } from './custom'
import { general } from './general'
import { overrides } from './overrides'

const darkThemeConfig = merge(baseThemeConfig, general, custom, {
  overrides: overrides
})

export default darkThemeConfig
