import { useContext, useEffect } from 'react'
import { HOME_SNAPSHOT_VIEW } from '../build/BuildHomeSnapshotPanel'
import { gql, useMutation, useQuery } from '@apollo/client'
import sortBy from 'lodash/sortBy'
import AppContext from '../AppContext'
import { DOMAIN_VIEW } from '../environment/environmentQueries'
import { getTeam } from '../team/teamQueries'
import { createArrayUpdater } from '../util'
import { CERT_VIEW } from './settings/cert/certQueries'

const ONBOARDING_VIEW = gql`
  fragment OnboardingView on Onboarding {
    id
    data
    completedAt
    dismissedAt
    checkingStatusAt
    steps {
      name
      status
      message
    }
  }
`

export const getOnboarding = gql`
  query getOnboarding($siteId: ID!) {
    onboarding(siteId: $siteId) {
      ...OnboardingView
    }
  }
  ${ONBOARDING_VIEW}
`

const onboardingSubscription = gql`
  subscription onboardingSubscription($siteId: ID!) {
    siteOnboardingUpdated(siteId: $siteId) {
      updated {
        ...OnboardingView
      }
    }
  }
  ${ONBOARDING_VIEW}
`

const refreshOnboarding = gql`
  mutation refreshOnboarding($siteId: ID!) {
    refreshOnboarding(siteId: $siteId) {
      onboarding {
        ...OnboardingView
      }
    }
  }
  ${ONBOARDING_VIEW}
`

export const useRefreshOnboarding = (siteId) => {
  const [mutate] = useMutation(refreshOnboarding)

  return () => mutate({ variables: { siteId } })
}

export const useGetOnboardingQuery = (siteId) => {
  const queryResults = useQuery(getOnboarding, {
    variables: { siteId }
  })

  const { subscribeToMore } = queryResults

  useEffect(() => {
    if (siteId) {
      return subscribeToMore({
        document: onboardingSubscription,
        variables: { siteId }
      })
    }
  }, [siteId])

  return queryResults
}

const dismissOnboarding = gql`
  mutation dismissOnboarding($siteId: ID!) {
    dismissOnboarding(siteId: $siteId)
  }
`

export const useDismissOnboarding = (siteId) => {
  const [mutate] = useMutation(dismissOnboarding)

  return () => mutate({ variables: { siteId } })
}

export const ENVIRONMENT_LIST_VIEW = gql`
  fragment EnvironmentListView on Environment {
    id
    name
    default
    fastlyDefaultDomainName
    activeEdgeConfiguration {
      id
    }
    limelightDefaultDomainName
    limelightApexIps
    domainNames
    activeUrls
    production
    fastlyServiceId
    awsAccount {
      id
      free
    }
    activeVersion {
      id
      version
      httpRequestLogging
    }
    activeBuild {
      id
    }
    latestBuild {
      id
      status
      number
      permalinkUrl
      xdnVersion
      createdAt
      homeSnapshot {
        ...homeSnapshotView
      }
      user {
        email
      }
    }
  }
  ${HOME_SNAPSHOT_VIEW}
`

export const getEnvironments = gql`
  query getEnvironments($siteId: ID!) {
    environments: environmentsBySite(siteId: $siteId, orderBy: [{ default: desc }, { name: asc }]) {
      nodes {
        ...EnvironmentListView
      }
    }
  }
  ${ENVIRONMENT_LIST_VIEW}
`

export const siteEnvironmentsSubscription = gql`
  subscription getSiteEnvironmentsSubscription($siteId: ID!) {
    siteEnvironmentsUpdated(siteId: $siteId) {
      new {
        ...EnvironmentListView
      }
      updated {
        ...EnvironmentListView
      }
      deleted
    }
  }
  ${ENVIRONMENT_LIST_VIEW}
`

// Environments list is sorted by getEnvironments query, but they may need to be resorted
// when updated by subscription
const sortEnvironments = (environments) => {
  return sortBy(environments, [(e) => !e.default, (e) => e.name])
}

export const useGetEnvironmentsQuery = (siteId, options) => {
  const queryResults = useQuery(getEnvironments, {
    variables: { siteId },
    ...options
  })

  const { subscribeToMore } = queryResults

  useEffect(() => {
    if (siteId) {
      return subscribeToMore({
        document: siteEnvironmentsSubscription,
        variables: { siteId },
        updateQuery: createArrayUpdater(
          (prev) => prev.environments,
          ({ subscriptionData: { data } }) => data.siteEnvironmentsUpdated,
          { sortFn: sortEnvironments }
        )
      })
    }
  }, [siteId])

  return queryResults
}

const deleteSite = gql`
  mutation deleteSite($id: ID!) {
    deleteSite(id: $id)
  }
`

export const useDeleteSite = () => {
  const [mutate] = useMutation(deleteSite)
  // Note that we don't need to refetch team sites, it will be updated
  // by the subscription
  return (siteId) => mutate({ variables: { id: siteId } })
}

const deleteInactiveBuilds = gql`
  mutation deleteInactiveBuilds($id: ID!) {
    deleteInactiveBuilds(id: $id)
  }
`

export const useDeleteInactiveBuilds = () => {
  const [mutate] = useMutation(deleteInactiveBuilds)
  return (siteId) => mutate({ variables: { id: siteId } })
}

const SITE_SETTINGS_VIEW = gql`
  fragment SiteSettingsView on Site {
    id
    slug
    hasRumData
    maxMemorySize
    minMemorySize
    currentMemorySize
    defaultMemorySize
    skipFastly
    team {
      id
      tier
    }
    activeDomains {
      ...DomainView
    }
    cert {
      ...CertView
    }
  }
  ${DOMAIN_VIEW}
  ${CERT_VIEW}
`

const getSiteSettings = gql`
  query site($teamSlug: String!, $slug: String!) {
    site(teamSlug: $teamSlug, slug: $slug) {
      ...SiteSettingsView
    }
  }
  ${SITE_SETTINGS_VIEW}
`

export const siteSubscription = gql`
  subscription getSiteUpdated($siteId: ID!) {
    siteUpdated(siteId: $siteId) {
      new {
        ...SiteSettingsView
      }
      updated {
        ...SiteSettingsView
      }
      deleted
    }
  }
  ${SITE_SETTINGS_VIEW}
`

export const useGetSite = (variables, options = {}) => {
  return useQuery(getSiteSettings, { variables, skip: !variables.teamSlug, ...options })
}

const createGithubSite = gql`
  mutation createGithubSite(
    $siteSlug: String!
    $teamSlug: String!
    $accessToken: String!
    $templateRepo: String!
  ) {
    createGithubSite(
      siteSlug: $siteSlug
      teamSlug: $teamSlug
      accessToken: $accessToken
      templateRepo: $templateRepo
    ) {
      site {
        ...SiteSettingsView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${SITE_SETTINGS_VIEW}
`

export const useCreateGithubSiteMutation = () => {
  const [mutate, mutationResult] = useMutation(createGithubSite)

  return [
    async ({ siteSlug, teamSlug, accessToken, templateRepo }) => {
      const { data } = await mutate({
        variables: { siteSlug, teamSlug, accessToken, templateRepo }
      })
      return data.createGithubSite
    },
    mutationResult
  ]
}

const updateSite = gql`
  mutation updateSite($site: UpdateSiteAttributes!) {
    updateSite(site: $site) {
      site {
        ...SiteSettingsView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${SITE_SETTINGS_VIEW}
`

const updateSiteSsl = gql`
  mutation updateSiteSsl($site: UpdateSslAttributes!) {
    updateSiteSsl(site: $site) {
      site {
        ...SiteSettingsView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${SITE_SETTINGS_VIEW}
`

export const useUpdateSiteMutation = () => {
  const [mutate, mutationResult] = useMutation(updateSite)

  return [
    async (site) => {
      const { data } = await mutate({
        variables: { site }
      })
      return data.updateSite
    },
    mutationResult
  ]
}

export const useUpdateSiteSslMutation = () => {
  const [mutate, mutationResult] = useMutation(updateSiteSsl)

  return [
    async (site) => {
      const { data } = await mutate({
        variables: { site }
      })
      return data.updateSiteSsl
    },
    mutationResult
  ]
}

const moveSite = gql`
  mutation moveSite($siteId: ID!, $teamId: ID!) {
    moveSite(siteId: $siteId, teamId: $teamId)
  }
`

export const useMoveSiteMutation = () => {
  const [mutate] = useMutation(moveSite)

  return (siteId, teamId) => mutate({ variables: { siteId, teamId } })
}

const createSite = gql`
  mutation createSite(
    $site: CreateSiteAttributes!
    $originalHost: String
    $bypassOriginalHostCheck: Boolean
  ) {
    createSite(
      site: $site
      originalHost: $originalHost
      bypassOriginalHostCheck: $bypassOriginalHostCheck
    ) {
      site {
        slug
      }
      userErrors {
        message
        path
        bypassable
      }
    }
  }
`

export const useCreateSite = () => {
  const { currentTeam } = useContext(AppContext)

  const [mutate] = useMutation(createSite)
  return (site, originalHost, bypassOriginalHostCheck) =>
    mutate({
      variables: { site, originalHost, bypassOriginalHostCheck },
      refetchQueries: [{ query: getTeam, variables: { slug: currentTeam.slug } }],
      awaitRefetchQueries: true
    })
}

const sendOutSiteAlertsMutation = gql`
  mutation sendAllSiteAlerts($siteId: ID!) {
    sendAllSiteAlerts(id: $siteId) {
      success
      userErrors {
        message
        path
      }
    }
  }
`

export const useSendOutSiteAlertsMutation = () => {
  const [mutate, mutationResult] = useMutation(sendOutSiteAlertsMutation)

  return [
    async (siteId) => {
      const { data } = await mutate({ variables: { siteId } })
      return data.sendAllSiteAlerts
    },
    mutationResult
  ]
}
