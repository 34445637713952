import { ReactElement } from 'react'
import MuiIconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '../../../../theme'
import { TIconButton } from '../types'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // TODO: for now just only 1 option, then extend when necessary
    color: theme.palette.danger.dark
  }
}))

export const IconButton = ({
  children,
  onClick,
  isDanger,
  size
}: TIconButton): ReactElement<TIconButton> => {
  const classes = useStyles()

  return (
    <MuiIconButton
      classes={{ root: isDanger ? classes.root : '' }}
      size={size ? size : 'medium'}
      onClick={onClick}
    >
      {children}
    </MuiIconButton>
  )
}
