import React, { createContext, useState, useEffect } from 'react'
import { useUpdateUser } from './user/userQueries'

const DarkModeContext = createContext()

function isOsDark() {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
}

function getInitial(user) {
  if (user?.darkMode !== undefined) return user.darkMode
  try {
    const local = localStorage.getItem('darkMode')
    if (local !== undefined) return local === 'true'
  } catch (e) {
    // localstorage access can fail for various reasons, like browser settings or plugins, just ignore
  }
  return isOsDark()
}

export default DarkModeContext

export const DarkModeContextProvider = ({ currentUser, children }) => {
  // Initialize dark mode by checking user setting, local storage setting, or OS setting
  const [darkMode, setDarkMode] = useState(getInitial(currentUser))
  const [updateUser] = useUpdateUser()

  useEffect(() => {
    // Only update if necessary
    if (currentUser && darkMode !== currentUser.darkMode) {
      updateUser({
        id: currentUser.id,
        darkMode
      })
    }
    try {
      localStorage.setItem('darkMode', darkMode ? 'true' : 'false')
    } catch (e) {
      // might throw due to permission issues
    }
  }, [darkMode])

  const value = {
    darkMode,
    setDarkMode
  }

  return <DarkModeContext.Provider value={value}>{children}</DarkModeContext.Provider>
}
