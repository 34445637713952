import { useMutation, gql } from '@apollo/client'
import { useContext } from 'react'
import AppContext from '../AppContext'
import { useNotifications } from '../util'

export const SITE_LIST_VIEW = gql`
  fragment SiteListView on Site {
    id
    slug
  }
`

export const TEAM_LIST_VIEW = gql`
  fragment TeamListView on Team {
    id
    name
    slug
    personal
    samlEnabled
    tier
    sites {
      nodes {
        ...SiteListView
      }
    }
  }
  ${SITE_LIST_VIEW}
`

export const USER_VIEW = gql`
  fragment UserView on User {
    id
    email
    fullname
    staff
    staffRole
    githubUser
    githubDetails
    githubAccessToken
    googleUserId
    googleUserEmail
    avatarUrl
    unconfirmedEmail
    tosAccepted
    temporaryPassword
    dateTimeDisplay
    dateTimeFormat
    timeZoneDisplay
    darkMode
    activeStaffSession {
      id
      role
      activeUntil
      accessList
    }
    personalTeam {
      id
      name
      slug
    }
    organizationMembers {
      nodes {
        organization {
          id
          name
          slug
          teams {
            nodes {
              ...TeamListView
            }
          }
        }
        role
      }
    }
    members {
      nodes {
        team {
          ...TeamListView
        }
        role
      }
    }
  }
  ${TEAM_LIST_VIEW}
`

export const unlinkGithubMutation = gql`
  mutation unlinkGithub {
    unlinkGithub {
      ...UserView
    }
  }
  ${USER_VIEW}
`

export const unlinkGoogleMutation = gql`
  mutation unlinkGoogle {
    unlinkGoogle {
      ...UserView
    }
  }
  ${USER_VIEW}
`

export const sendApiKeyToCli = gql`
  mutation sendApiKeyToCli($name: String!, $sid: String!) {
    sendApiKeyToCli(name: $name, sid: $sid)
  }
`

const updateUser = gql`
  mutation updateUser($user: UpdateUserAttributes!) {
    updateUser(user: $user) {
      user {
        ...UserView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${USER_VIEW}
`

export const useUpdateUser = () => {
  const [mutate, mutationResult] = useMutation(updateUser)

  return [
    async (payload) => {
      const { data } = await mutate({
        variables: { user: payload }
      })
      return data.updateUser
    },
    mutationResult
  ]
}

const deleteUser = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      success
      userErrors {
        message
      }
    }
  }
`

export const useDeleteCurrentUser = () => {
  const { currentUser } = useContext(AppContext)
  const notifications = useNotifications()

  const [mutate] = useMutation(deleteUser)

  return async () => {
    const {
      data: {
        deleteUser: { success, userErrors }
      }
    } = await mutate({
      variables: { id: currentUser.id }
    })

    if (userErrors) {
      userErrors.forEach(({ message }) => notifications.error(message))
    }

    return success
  }
}
