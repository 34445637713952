import { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { Edit, Trash2 } from 'icons'
import { Typography } from '../../../Typography'
import { Theme } from '../../../theme'
import { Chip, IconButton } from '../../atoms'
import { TRulesActionPanel, TRulesActionPanelBadge } from './types'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1.25, 1.25, 1.25, 2.5),
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.type === 'dark' ? theme.palette.borderColor.main : '#DCDEE0',
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.paper : '#FAFBFB'
  },
  withBadge: {
    padding: theme.spacing(1.25)
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  text: {
    display: 'flex',
    gap: theme.spacing(0.375)
  },
  badge: {
    marginRight: theme.spacing(1.25)
  },
  buttons: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    margin: theme.spacing(-0.25, -0.25, 0, 0)
  }
}))

const RuleBadge = ({ badge, className }: { badge: TRulesActionPanelBadge; className?: string }) => (
  <Chip label={badge} className={className} />
)

export const RulesActionPanel = ({
  ruleName,
  ruleTypeLabel,
  badge,
  onEdit,
  onDelete
}: TRulesActionPanel): ReactElement<TRulesActionPanel> => {
  const classes = useStyles()

  return (
    <div
      className={cx(classes.root, {
        [classes.withBadge]: badge
      })}
    >
      <div className={classes.inner}>
        <div className={classes.text}>
          {badge && <RuleBadge badge={badge} className={classes.badge} />}
          <Typography variant="body2">{ruleName}</Typography>
          {ruleTypeLabel && (
            <Typography variant="body2" fontWeight={500}>
              {ruleTypeLabel}
            </Typography>
          )}
        </div>
        <div className={classes.buttons}>
          {onDelete && (
            <IconButton size={'small'} color={'default'} onClick={onDelete}>
              <Trash2 />
            </IconButton>
          )}
          {onEdit && (
            <IconButton size={'small'} color={'default'} onClick={onEdit}>
              <Edit />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  )
}
