import { green, grey, orange, red, yellow } from '@material-ui/core/colors'
import { lighten } from '@material-ui/core/styles'
import { CacheStatusColorsType, WebVitalsColorsType } from './themeTypes'

export const customColors = {
  White: '#FFFFFF',
  Cultured: '#F6F6F6',
  SilverSand: '#C4C4C4',
  GraniteGray: '#606060',
  Jet: '#353535',
  EerieBlack: '#1A1A1A',
  RaspberryPink: '#E95495',
  EdgioBlue: '#00A2E2',
  CerisePink: '#E74089',
  PastelRed: '#FF6161',
  BleuDeFrance: '#2993E0',
  MediumSlateBlue: '#7972FC',
  Black: '#000000'
}

export const cacheStatusColors = {} as CacheStatusColorsType

export const lightCacheStatusColors = {
  red: red['A700'],
  lightRed: lighten(red['A700'], 0.5),
  yellow: yellow[600],
  yellowText: '#e90',
  lightYellow: lighten(yellow[600], 0.45),
  green: green[400],
  lightGreen: lighten(green[400], 0.5),
  gray: grey[300]
}

export const darkCacheStatusColors = {
  red: red['500'],
  lightRed: lighten(red['500'], 0.5),
  yellow: yellow[600],
  yellowText: '#e90',
  lightYellow: lighten(yellow[600], 0.45),
  green: green[400],
  lightGreen: lighten(green[400], 0.5),
  gray: grey[300]
}

export const webVitalsColors: WebVitalsColorsType = {
  good: green[500],
  fair: orange[400],
  poor: red[500]
}

export const primaryColors = {
  main: customColors.EdgioBlue,
  dark: customColors.EdgioBlue,
  contrastText: customColors.White
}
