import { gql, useMutation } from '@apollo/client'
import produce from 'immer'
import { getEnvironmentVersion } from '../environmentQueries'

export const ENV_VAR_VIEW = gql`
  fragment EnvVarView on EnvironmentVariable {
    id
    key
    safeValue
    value
    secret
  }
`

// Add variable
const addEnvironmentVar = gql`
  mutation createEnvVar(
    $environmentVersionId: ID!
    $key: String!
    $value: String!
    $secret: Boolean!
  ) {
    createEnvironmentVariable(
      environmentVariable: {
        environmentVersionId: $environmentVersionId
        key: $key
        value: $value
        secret: $secret
      }
    ) {
      environmentVariable {
        ...EnvVarView
      }
      userErrors {
        path
        message
      }
    }
  }
  ${ENV_VAR_VIEW}
`

export const useAddEnvironmentVariableMutation = () => {
  const [mutate, mutationResult] = useMutation(addEnvironmentVar)

  return [
    async (environmentVersionId, { key, value = '', secret = true }) => {
      const { data } = await mutate({
        variables: { environmentVersionId, key, value, secret },
        update: (
          cache,
          {
            data: {
              createEnvironmentVariable: { environmentVariable }
            }
          }
        ) => {
          if (environmentVariable) {
            const query = getEnvironmentVersion
            const variables = { id: environmentVersionId }
            cache.updateQuery(
              { query, variables },
              produce((draft) => {
                draft.environmentVersion.environmentVariables.nodes.push(environmentVariable)
              })
            )
          }
        }
      })

      return data.createEnvironmentVariable
    },
    mutationResult
  ]
}

// Update variable
const updateEnvironmentVar = gql`
  mutation updateEnvVar($varId: ID!, $key: String!, $value: String!, $secret: Boolean!) {
    updateEnvironmentVariable(
      environmentVariable: { id: $varId, key: $key, value: $value, secret: $secret }
    ) {
      environmentVariable {
        ...EnvVarView
      }
      userErrors {
        path
        message
      }
    }
  }
  ${ENV_VAR_VIEW}
`

export const useUpdateEnvironmentVariableMutation = () => {
  const [mutate, mutationResult] = useMutation(updateEnvironmentVar)

  return [
    async ({ id, key, value = '', secret }) => {
      const { data } = await mutate({
        variables: { varId: id, key, value, secret }
      })
      return data.updateEnvironmentVariable
    },
    mutationResult
  ]
}

// Delete variable
const deleteEnvironmentVar = gql`
  mutation deleteEnvVar($varId: ID!) {
    deleteEnvironmentVariable(id: $varId)
  }
`

export const useDeleteEnvironmentVariableMutation = () => {
  const [mutate, mutationResult] = useMutation(deleteEnvironmentVar)

  return [
    async (varId, environmentVersionId) => {
      const { data } = await mutate({
        variables: { varId },
        update: (cache, { data: { deleteEnvironmentVariable } }) => {
          if (deleteEnvironmentVariable) {
            const query = getEnvironmentVersion
            const variables = { id: environmentVersionId }
            cache.updateQuery(
              { query, variables },
              produce((draft) => {
                const currentEnvVariables = draft.environmentVersion.environmentVariables.nodes
                draft.environmentVersion.environmentVariables.nodes = currentEnvVariables.filter(
                  (variable) => variable.id !== varId
                )
              })
            )
          }
        }
      })
      return data.deleteEnvironmentVariable
    },
    mutationResult
  ]
}
