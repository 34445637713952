import { Overrides } from '@material-ui/core/styles/overrides'
import { Theme } from './'

export const overrides = (theme: Theme): Overrides => ({
  MuiCssBaseline: {
    '@global': {
      '#root .App main': {
        '& code': {
          color: theme.palette.text.primary,
          borderColor: theme.palette.divider,
          ...theme.typography.code
        }
      },
      [`input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus`]: {
        ...theme.typography.body1,
        '-webkit-box-shadow': `0 0 0px 1000px ${theme.palette.background.paper} inset !important`
      },
      '.statusGoodText': {
        color: `${theme.palette.cacheStatus.green} !important`
      },
      '.statusFairText': { color: `${theme.palette.cacheStatus.yellowText} !important` },
      '.statusPoorText': { color: `${theme.palette.cacheStatus.red} !important` }
    }
  },
  MuiToolbar: {
    gutters: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    }
  },
  MuiCardActions: {
    root: {
      padding: theme.spacing(2, 0, 0, 0)
    }
  },
  MuiCardContent: {
    root: {
      '&:last-child': {
        paddingBottom: theme.spacing(2)
      }
    }
  },
  MuiDialogActions: {
    root: {
      paddingTop: theme.spacing(3)
    }
  },
  MuiInputBase: {
    input: {
      padding: theme.spacing(1.25, 1)
    }
  },
  MuiInputLabel: {
    root: {
      marginBottom: `${theme.spacing(0.5)} !important`
    }
  }
})
