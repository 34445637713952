import { ChangeEvent, Ref } from 'react'
import { BaseInput, CommonInputDefaultProps, CommonInputProps } from './components/BaseInput'

export interface Props extends CommonInputProps {
  /**
   * @ignore
   * Used only by the `AutoComplete` component
   */
  inputProps?: object
  /**
   * @ignore
   * Used only by the `AutoComplete` component
   */
  InputProps?: object
  /**
   * @ignore
   * Used only by the `DatePicker` component
   */
  inputRef?: Ref<HTMLInputElement>
  /**
   * @ignore
   * Used only by the `Select` component
   */
  labelRequired?: boolean
  /** Callback fired when `input` value changes. */
  onChange?: (event: ChangeEvent) => void
  /**
   * Type of the `input` element. It should be
   * <a href="https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill" target="_blank">a valid HTML5 input type</a>.
   */
  type?: string
  /** The value of the `input` element, required for a controlled component. */
  value?: string
  flexWrap?: boolean
}

/**
 * A text input.
 */
export function TextField({
  autoComplete,
  autoFocus,
  disabled,
  endAdornment,
  error,
  fullWidth,
  gutterBottom,
  helperText,
  inputProps,
  InputProps,
  inputRef,
  label,
  labelRequired = false,
  large,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  startAdornment,
  type = 'text',
  value,
  flexWrap
}: Props) {
  return (
    <BaseInput
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={disabled}
      endAdornment={endAdornment}
      error={error}
      fullWidth={fullWidth}
      gutterBottom={gutterBottom}
      helperText={helperText}
      inputProps={inputProps}
      InputProps={InputProps}
      inputRef={inputRef}
      label={label}
      labelRequired={labelRequired}
      large={large}
      name={name}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      startAdornment={startAdornment}
      type={type}
      value={value}
      flexWrap={flexWrap}
    />
  )
}

TextField.defaultProps = CommonInputDefaultProps
