import React from 'react'
import { Box, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button } from 'ui'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(12)
  }
}))

export default function TopLevelErrorPage() {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={classes.root}
    >
      <Typography variant="h1" gutterBottom>
        Something went wrong.
      </Typography>
      <Typography gutterBottom>
        We're sorry, it looks like the page you requested could not be loaded at this time. Please
        try again later.
      </Typography>
      <Box marginTop={2}>
        <Button variant="contained" onClick={() => window.location.reload()} large>
          Try Again
        </Button>
      </Box>
    </Box>
  )
}
