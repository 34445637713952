import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.action.disabled,
    '&.focused': {
      color: theme.palette.action.active
    }
  }
}))

interface Props {
  children: JSX.Element
  focused?: boolean
  position: 'start' | 'end'
}

export function Adornment({ children, focused = false, position }: Props) {
  const classes = useStyles()

  return (
    <InputAdornment
      classes={classes}
      className={focused ? 'focused' : ''}
      disablePointerEvents
      disableTypography
      position={position}
    >
      {children}
    </InputAdornment>
  )
}
