import React, { useState } from 'react'
import { InputAdornment } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { TextField } from './TextField'
import { CommonInputDefaultProps, CommonInputProps } from './components/BaseInput'
import { ClearButton } from './components/ClearButton'

export interface Props extends Omit<CommonInputProps, 'endAdornment' | 'startAdornment'> {
  /** Format string. */
  format?: string
  /** Max selectable date. */
  maxDate?: Date
  /** Callback fired when `datepicker` value changes. */
  onChange?: (_value: Date | null) => void
  /** The value of the `input` element, required for a controlled component. */
  value?: Date | null
}

/**
 * A date input.
 */
// Although KeyboardDatePicker allows custom components, the type definitions do not always reflect that.
export function DatePicker({
  autoComplete,
  autoFocus,
  disabled,
  error,
  format = 'MM/dd/yyyy',
  fullWidth,
  gutterBottom,
  helperText,
  label,
  large,
  maxDate,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  value
}: Props) {
  const dateFns = new DateFnsUtils()
  const [selectedDate, setSelectedDate] = useState(value || null)

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        autoOk
        disabled={disabled}
        disableFuture
        disableToolbar
        error={error}
        format={format}
        fullWidth={fullWidth}
        // @ts-ignore Custom prop
        gutterBottom={gutterBottom}
        helperText={helperText}
        InputAdornmentProps={{ position: 'start' }}
        InputProps={{
          endAdornment: selectedDate && (
            <InputAdornment position="end">
              <ClearButton
                onClick={() => {
                  setSelectedDate(null)
                  onChange && onChange(null)
                }}
              />
            </InputAdornment>
          )
        }}
        KeyboardButtonProps={{ size: 'small' }}
        label={label}
        // @ts-ignore Custom prop
        large={large}
        name={name}
        maxDate={maxDate}
        onBlur={onBlur}
        onChange={(date) => {
          setSelectedDate(date)
          if (onChange && dateFns.isValid(date)) {
            onChange(date)
          }
        }}
        onFocus={onFocus}
        placeholder={placeholder || format}
        required={required}
        // @ts-ignore Type definitions expect one of Material-UI's TextFields here, but this also works with custom components
        TextFieldComponent={TextField}
        value={selectedDate}
        variant="inline"
      />
    </MuiPickersUtilsProvider>
  )
}

DatePicker.defaultProps = CommonInputDefaultProps
