import React, { createContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { InternalError } from 'ui'
import { useApolloClient } from '@apollo/client'
import Cookies from 'js-cookie'
import get from 'lodash/get'
import { staffCanAdminAllSites } from './admin/StaffContext'
import { useGetOrganizationQuery } from './organization/organizationQueries'
import { useGetEnvironmentsQuery } from './site/siteQueries'
import { useGetTeamQuery } from './team/teamQueries'
import useNotifications from './util/useNotifications'

const AppContext = createContext()
export default AppContext

export const DATE_FORMAT_STRING = "EEEE, MMMM d, yyyy 'at' h:mm aaa"
export const EU_DATE_FORMAT_STRING = "EEEE, d MMMM, yyyy 'at' H:mm aaa"

export const AppContextProvider = ({ currentUser, onLogout, refetchCurrentUser, children }) => {
  const client = useApolloClient()
  const router = useRouter()
  const notifications = useNotifications()

  let { query, asPath: currentUrl, push } = router

  const { organizationSlug, teamSlug, siteSlug, environmentName } = query

  teamSlug = teamSlug || currentUser?.personalTeam?.slug

  // Let's use personal team as a minimal context in case there is no team slug for some features like spotlight search
  // Example of those cases can be when user is on user account page or administrator page
  const {
    team: currentTeam,
    teamFeatures: currentTeamFeatures,
    loading: teamLoading,
    error: teamError,
    refetch: refetchTeam
  } = useGetTeamQuery(teamSlug, { skip: !teamSlug })

  const {
    organization: currentOrganization,
    loading: organizationLoading,
    error: organizationError,
    refetch: refetchOrganization
  } = useGetOrganizationQuery(organizationSlug, { skip: !organizationSlug })

  client.onInternalError((message) => {
    notifications.error(message, {
      preventDuplicate: true
    })
  })

  // Trigger a network refetch on team change in case updates have happened between changing teams
  useEffect(() => {
    if (teamSlug && refetchTeam) {
      refetchTeam()
    }
  }, [teamSlug, refetchTeam])

  // Trigger a network refetch on org change in case updates have happened between changing orgs
  useEffect(() => {
    if (organizationSlug && refetchOrganization) {
      refetchOrganization()
    }
  }, [organizationSlug, refetchOrganization])

  /**
   *
   *
   * User context
   *
   *
   */
  client.onUnauthorized(async (message) => {
    // We only do the logout procedure if currentUser exists otherwise we may end up doing double redirect
    // Double redirect will redirect user back to login page after logging in
    if (currentUser) {
      if (window.location.pathname !== '/login') {
        await push(`/login?redirectTo=${encodeURIComponent(currentUrl)}`)
      }

      onLogout()
      notifications.warning(message, {
        preventDuplicate: true
      })
    }
  })

  /**
   *
   *
   * Teams and membership context
   *
   *
   */
  // This is used when you make a change to an instance that is part of current route
  // which could result in 404 glitch (ex: changing environment name)
  // Instead we set this loading, update the instance, navigate to the new one and remove the loader
  const [routeIsLoading, setRouteIsLoading] = useState(false)
  const setLastVisitedTeamCookie = (team) => {
    // Note that this cookie is also used as a flag to detect that a user already signed-in in the past
    // so that he does not land on the signup page. For that use case, we want to keep a very long TTL here.
    Cookies.set(`${currentUser.id}_lastVisitedTeam`, team.slug, { expires: 365 })
  }
  useEffect(() => {
    if (currentTeam) {
      setLastVisitedTeamCookie(currentTeam)
    }
  }, [currentTeam])

  const teamMemberships = get(currentUser, 'members.nodes', [])
  const orgMemberships = get(currentUser, 'organizationMembers.nodes', [])

  const teamsFromMembership = teamMemberships.map((m) => m.team)
  const organizations = orgMemberships.map((om) => om.organization)
  const teamsFromOrgs = organizations.map((o) => o.teams.nodes).flat()

  // Since membership can be both ways at the same time, filter unique values from both
  // relations. From memberships between teams and orgs.
  const teams = [
    ...new Map(teamsFromMembership.concat(teamsFromOrgs).map((item) => [item.id, item])).values()
  ]

  if (currentTeam && !teams.find((t) => t.id === currentTeam.id)) {
    // This happens in the rare case where a staff access a team that is not among its team
    teams.push(currentTeam)
  }

  function getIsTeamAdmin({ slug }) {
    const teamMembership = slug && teamMemberships.find((m) => m.team.slug === slug.toLowerCase())

    const orgMembership =
      currentTeam &&
      orgMemberships.find((m) => m.organization.slug === currentTeam.organization?.slug)

    return (
      staffCanAdminAllSites(currentUser) ||
      // When either org or team member role is admin that means that user can have admin rights on a team
      [teamMembership?.role, orgMembership?.role].includes('admin') ||
      [teamMembership?.role, orgMembership?.role].includes('super_admin')
    )
  }

  function getIsOrgAdmin({ slug }) {
    const orgMembership = slug && orgMemberships.find((m) => m.organization.slug === slug)
    return (
      staffCanAdminAllSites(currentUser) ||
      orgMembership?.role === 'admin' ||
      orgMembership?.role === 'super_admin'
    )
  }

  const isAdmin = getIsTeamAdmin({ slug: teamSlug })
  const isOrgAdmin = getIsOrgAdmin({ slug: organizationSlug })

  /**
   *
   *
   * Sites context
   *
   *
   */
  const currentTeamSites = get(currentTeam, 'sites.nodes', [])
  const currentSite = siteSlug && currentTeamSites.find((t) => t.slug === siteSlug.toLowerCase())

  /**
   *
   *
   * Environments context
   *
   *
   */
  const {
    loading: environmentsLoading,
    data: environmentsData,
    error: environmentsError
  } = useGetEnvironmentsQuery(currentSite && currentSite.id, {
    skip: !currentSite
  })

  // currentSite condition is important. If you select another team, currentSite becomes undefined
  // but environmentResults still contains old environments
  const currentSiteEnvironments = get(currentSite && environmentsData, 'environments.nodes', [])

  const currentEnvironment =
    environmentName && currentSiteEnvironments.find((e) => e.name === environmentName)

  const activeBuildIdToEnvironment = {}
  if (currentSiteEnvironments) {
    for (const env of currentSiteEnvironments) {
      if (env.activeBuild) {
        activeBuildIdToEnvironment[env.activeBuild.id] = env
      }
    }
  }

  const isLatestBuild = (build) => {
    return currentEnvironment.latestBuild && currentEnvironment.latestBuild.id === build.id
  }

  const getEnvironmentActiveUrl = (environment) => {
    return environment.activeUrls[0]
  }

  const getBuildActiveUrl = (build) => {
    return activeBuildIdToEnvironment[build.id]
      ? getEnvironmentActiveUrl(activeBuildIdToEnvironment[build.id])
      : build.permalinkUrl
  }

  const isEnterprise = currentTeam && currentTeam.tier === 'enterprise'
  const hasEnterpriseTeam = teams.some((team) => team.tier === 'enterprise')

  const error = [environmentsError, teamError, organizationError].find((e) => e)
  if (error) {
    return <InternalError error={error} />
  }

  const context = {
    organizationLoading,
    currentOrganization,
    organizations,
    teamLoading,
    currentTeam,
    teams,
    currentTeamFeatures,
    isEnterprise,
    hasEnterpriseTeam,
    teamSlug: currentTeam && currentTeam.slug,
    organizationSlug,
    isAdmin,
    getIsTeamAdmin,
    getIsOrgAdmin,
    isOrgAdmin,
    currentTeamSites,
    currentSite,
    currentSiteEnvironments,
    currentEnvironment,
    environmentsLoading,
    siteSlug: currentSite && currentSite.slug,
    onLogout,
    refetchCurrentUser,
    dateFormat: currentUser?.dateTimeFormat === 'eu' ? EU_DATE_FORMAT_STRING : DATE_FORMAT_STRING,
    currentUser,
    getBuildActiveUrl,
    getEnvironmentActiveUrl,
    setRouteIsLoading,
    isLatestBuild
  }

  if (
    routeIsLoading ||
    (organizationSlug && organizationLoading && !currentOrganization) ||
    (teamSlug && teamLoading && !currentTeam) ||
    (siteSlug && teamLoading && !currentSite) ||
    (environmentName && environmentsLoading && !currentEnvironment)
  ) {
    // This is mostly useful on initial load when there is no Team/Environment loaded at all,
    // and we don't want to call children components without a team, which would require a lot of `team && ...`
    //
    // Once loaded, when we shift team/environment, the loading will be done deeper
    // in component tree in a more elegant way. (cf. teamLoading usage)
    context.loading = true
  } else if (
    (organizationSlug && !organizationLoading && !currentOrganization) ||
    (teamSlug && !teamLoading && !currentTeam) ||
    (siteSlug && !teamLoading && !currentSite) ||
    (environmentName && !environmentsLoading && !currentEnvironment)
  ) {
    context.notFound = true
    context.teamSlug = teamSlug
  }

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>
}
