export default function LoadingPage() {
  return (
    <>
      <style>
        {`
    
      .ball-loader {
        width: 100px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
      }
    
      .ball-loader-ball {
        will-change: transform;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        position: absolute;
        -webkit-animation: grow 1s ease-in-out infinite alternate;
                animation: grow 1s ease-in-out infinite alternate;
      }
      .ball-loader-ball.ball1 {
        background-color: #812991;
        left: 0;
        -webkit-transform-origin: 100% 50%;
                transform-origin: 100% 50%;
      }
      .ball-loader-ball.ball2 {
        background-color: #00A2E2;
        left: 50%;
        -webkit-transform: translateX(-50%) scale(1);
                transform: translateX(-50%) scale(1);
        -webkit-animation-delay: 0.33s;
                animation-delay: 0.33s;
      }
      .ball-loader-ball.ball3 {
        background-color: #01B18D;
        right: 0;
        -webkit-animation-delay: 0.66s;
                animation-delay: 0.66s;
      }
    
      @-webkit-keyframes grow {
        to {
          -webkit-transform: translateX(-50%) scale(0);
                  transform: translateX(-50%) scale(0);
        }
      }
    
      @keyframes grow {
        to {
          -webkit-transform: translateX(-50%) scale(0);
                  transform: translateX(-50%) scale(0);
        }
      }
    
      .ball-loader {
        display: none;
      }
    
      body.loading .ball-loader {
        display: block;
      }
      `}
      </style>
      <div className="ball-loader">
        <div className="ball-loader-ball ball1"></div>
        <div className="ball-loader-ball ball2"></div>
        <div className="ball-loader-ball ball3"></div>
      </div>
    </>
  )
}
