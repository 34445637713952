import { grey } from '@material-ui/core/colors'
import { customColors, lightCacheStatusColors } from '../customColors'

export const custom = {
  palette: {
    cacheStatus: lightCacheStatusColors,
    borderColor: {
      main: customColors.SilverSand
    },
    linkButtonColor: {
      main: '#01B18D' // TODO: get it later from DS
    }
  },
  typography: {
    code: {
      backgroundColor: grey[800]
    }
  }
}
