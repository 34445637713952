import React from 'react'
import { Box, Container, alpha, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link, Typography } from 'ui'
import Colors from 'ui/Colors'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
    backgroundColor: theme.palette.type === 'dark' ? Colors.Black : theme.palette.common.white,
    boxShadow: `0px -1px ${theme.spacing(1)}px ${alpha(Colors.Black, 0.2)}`
  }
}))

const FooterLink = ({ children, href, newTab = true }) => (
  <Box marginRight={4} marginY={1}>
    <Link color="textPrimary" href={href} newTab={newTab}>
      {children}
    </Link>
  </Box>
)

export default function Footer() {
  const classes = useStyles()
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <footer className={classes.root}>
      <Container maxWidth="xl">
        <Box display={mobile ? 'initial' : 'flex'} marginY={2.5}>
          <FooterLink href="https://docs.edg.io">Docs</FooterLink>
          <FooterLink href="https://forum.layer0.co">Forums</FooterLink>
          <FooterLink href="/help" newTab={false}>
            Support
          </FooterLink>
          <FooterLink href="https://edg.io/legal/terms-of-service">Terms</FooterLink>
          <FooterLink href="https://edg.io/legal/privacy-policy">Privacy</FooterLink>

          <Box marginLeft="auto" marginY={1}>
            <Typography variant="caption">
              © {new Date().getFullYear()} Edgio Inc. All rights reserved.
            </Typography>
          </Box>
        </Box>
      </Container>
    </footer>
  )
}
