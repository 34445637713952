import React from 'react'
import get from 'lodash/get'

const StaffContext = React.createContext()
export default StaffContext

export const MEMBER_ROLE = 'member'
export const ADMIN_ROLE = 'administrator'
export const GOD_ROLE = 'god'

export const STAFF_ROLES = {
  none: {
    value: 'none', // Will be sent to mutation as null
    label: 'None',
    description: 'Staff mode is disabled'
  },
  member: {
    value: MEMBER_ROLE,
    label: 'Member',
    description:
      'Grants member access to all teams. This is ideal to share a safe access to all LLNW employees.'
  },
  administrator: {
    value: ADMIN_ROLE,
    label: 'Administrator',
    description: 'Grants admin access to all teams. This is ideal for demos.'
  },
  god: {
    value: GOD_ROLE,
    label: 'God Mode',
    description:
      'Grants admin access to all teams. Also allows members alter customer account settings such as tier and Lambda memory, view Layer0 platform log messages, and download customer VCLs'
  }
}

const ROLE_ORDERED_BY_RESPONSIBILITY = [GOD_ROLE, ADMIN_ROLE, MEMBER_ROLE]
/**
 * Returns the roles available for a given base role.
 * For example a god staff can switch to Admin or Member
 * @param {} baseRole
 * @returns
 */
export function rolesAvailableFor(baseRole) {
  const roleIndex = ROLE_ORDERED_BY_RESPONSIBILITY.indexOf(baseRole)
  return ROLE_ORDERED_BY_RESPONSIBILITY.slice(roleIndex)
}

export function staffCanAdminAllSites(currentUser) {
  const role = get(currentUser, 'activeStaffSession.role')
  const roleIndex = ROLE_ORDERED_BY_RESPONSIBILITY.indexOf(role)
  return roleIndex > -1 && roleIndex <= ROLE_ORDERED_BY_RESPONSIBILITY.indexOf(ADMIN_ROLE)
}

export function StaffContextProvider({ children, currentUser }) {
  const staffRole = currentUser?.staffRole
  const activeStaffSession = currentUser?.activeStaffSession
  const activeStaffRole = activeStaffSession?.role
  const staffAccessList = get(currentUser, 'activeStaffSession.accessList', [])

  // Returns boolean whether the required operation is allowed for staff with
  // current access level.
  function can(operation) {
    return staffAccessList.includes(operation)
  }

  const contextValue = {
    // Can Read?
    canReadAdminTab: can('read_admin_tab'),
    canReadBuildArtifacts: can('read_build_artifacts'),
    canReadMetricsOverride: can('read_metrics_override'),
    canReadInternalLogs: can('read_internal_logs'),
    canSeeSiteStaffTab: can('can_see_site_staff_tab'),
    canSeeEnvironmentStaffTab: can('can_see_environment_staff_tab'),
    // Can Modify?
    canModifyStaffRoles: can('modify_staff_role'),
    canModifyCustomerCerts: can('modify_customer_certs'),
    canModifyAccountSettings: can('modify_account_settings'),
    canReadAwsAccountsTab: can('read_aws_accounts_tab'),
    // Helpers.
    can,
    isStaff: !!staffRole,
    staffRole,
    isGod: staffRole === 'god',
    activeStaffRole,
    isStaffActive: !!activeStaffSession,
    activeUntil: activeStaffSession?.activeUntil
  }

  return <StaffContext.Provider value={contextValue}>{children}</StaffContext.Provider>
}
