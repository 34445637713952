import { ReactElement } from 'react'
import { Chip as MuiChip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { TChipProps } from './types'

const useStyles = makeStyles(() => ({
  root: {
    // temporary hardcoded
    height: '20px',
    borderColor: '#01B18D',
    color: '#017C63',
    backgroundColor: '#D9F3EE'
  }
}))

export const Chip = ({ label, className }: TChipProps): ReactElement<TChipProps> => {
  const { root } = useStyles()

  return (
    <MuiChip
      variant="outlined"
      size="small"
      color="primary"
      label={label}
      classes={{ root: root }}
      className={className}
    />
  )
}
