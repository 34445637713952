import React, { useCallback, useMemo } from 'react'
import { IconButton } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { useSnackbar } from 'notistack'

// Keep alerts 10s instead of 5s by default. They can be dismissed manually too
const AUTO_HIDE_DEFAULT_DURATION = 10000
// keep errors displayed for 30s
const ERROR_DEFAULT_AUTO_HIDE_DURATION = 30000

/**
 * Opts can be found here:
 * https://iamhosseindhv.com/notistack/api
 */
export default function useNotifications(props) {
  const { closeButton = true } = props || {}
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const defaultOptions = useMemo(() => {
    const baseOptions = {
      autoHideDuration: AUTO_HIDE_DEFAULT_DURATION
    }

    if (closeButton) {
      return {
        ...baseOptions,
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)}>
            <Clear />
          </IconButton>
        )
      }
    } else {
      return baseOptions
    }
  }, [closeButton, closeSnackbar])

  const notify = useCallback((msg, opts = {}) => enqueueSnackbar(msg, opts), [enqueueSnackbar])
  const success = useCallback(
    (msg, opts = {}) => enqueueSnackbar(msg, { ...defaultOptions, ...opts, variant: 'success' }),
    [defaultOptions, enqueueSnackbar]
  )
  const info = useCallback(
    (msg, opts = {}) => enqueueSnackbar(msg, { ...defaultOptions, ...opts, variant: 'info' }),
    [defaultOptions, enqueueSnackbar]
  )
  const warning = useCallback(
    (msg, opts = {}) => enqueueSnackbar(msg, { ...defaultOptions, ...opts, variant: 'warning' }),
    [defaultOptions, enqueueSnackbar]
  )
  const error = useCallback(
    (msg, opts = {}) =>
      enqueueSnackbar(msg, {
        ...defaultOptions,
        autoHideDuration: ERROR_DEFAULT_AUTO_HIDE_DURATION,
        ...opts,
        variant: 'error'
      }),
    [defaultOptions, enqueueSnackbar]
  )

  const returnValue = useMemo(() => {
    return { notify, success, info, warning, error }
  }, [error, info, notify, success, warning])

  return returnValue
}
