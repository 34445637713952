// Generated with https://www.color-name.com/

const colors = {
  White: '#FFFFFF',
  Cultured: '#F6F6F6',
  SilverSand: '#C4C4C4',
  GraniteGray: '#606060',
  Jet: '#353535',
  EerieBlack: '#1A1A1A',
  RaspberryPink: '#E95495',
  EdgioBlue: '#00A2E2',
  CerisePink: '#E74089',
  PastelRed: '#FF6161',
  BleuDeFrance: '#2993E0',
  MediumSlateBlue: '#7972FC',
  Black: '#000000'
}

export default colors
