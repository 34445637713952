import { pink } from '@material-ui/core/colors'
import { customColors } from '../customColors'

export const general = {
  palette: {
    type: 'light',
    primary: {
      light: pink[400]
    },
    success: {
      light: pink[400]
    },
    text: {
      primary: customColors.EerieBlack,
      secondary: customColors.GraniteGray
    },
    background: {
      paper: customColors.White
    },
    action: {
      active: customColors.EerieBlack,
      disabledBackground: customColors.Cultured
    }
  },
  typography: {
    h1: {
      color: customColors.EerieBlack
    },
    subtitle1: {
      color: customColors.GraniteGray
    },
    body1: {
      color: customColors.EerieBlack
    },
    body2: {
      color: customColors.EerieBlack
    },
    caption: {
      color: customColors.GraniteGray
    }
  }
}
