// Constants must match with app/models/feature_flag.rb
const FEATURES = {
  SPLIT_TESTING_QUERY_PARAM_CRITERIA: 'SPLIT_TESTING_QUERY_PARAM_CRITERIA',
  ENVIRONMENT_REDIRECTS: 'ENVIRONMENT_REDIRECTS',
  ENVIRONMENT_REDIRECTS_TRIM_QS_OPTION: 'ENVIRONMENT_REDIRECTS_TRIM_QS_OPTION',
  ENVIRONMENT_REDIRECTS_FROM_URL: 'ENVIRONMENT_REDIRECTS_FROM_URL',
  ENVIRONMENT_PRESERVE_CACHE: 'ENVIRONMENT_PRESERVE_CACHE',
  ENVIRONMENT_SECURITY_ACL: 'ENVIRONMENT_SECURITY_ACL',
  ENVIRONMENT_BOT_MANAGEMENT: 'ENVIRONMENT_BOT_MANAGEMENT',
  CORE_WEB_VITALS: 'CORE_WEB_VITALS',
  BUILD_ROUTER_INFO: 'BUILD_ROUTER_INFO',
  BYO_FASTLY: 'BYO_FASTLY',
  NOTIFICATIONS: 'NOTIFICATIONS',
  HTTP_REQUEST_LOGGING: 'HTTP_REQUEST_LOGGING',
  LIMELIGHT_MIGRATION: 'LIMELIGHT_MIGRATION',
  CDN_ONBOARDING: 'CDN_ONBOARDING',
  SAML_SSO: 'SAML_SSO',
  // this indicates whether to show the security tab at all:
  SECURITY_WAF_VISIBLE: 'SECURITY_WAF_VISIBLE',
  // this indicates if WAFs can be created. If SECURITY_WAF_VISIBLE is ON but this is OFF, the tab will
  // show with a link for an upsell. SECURITY_WAF_VISIBLE is required for this flag to be turned on.
  SECURITY_WAF: 'SECURITY_WAF',
  // this indicates if BOT settings can be used for WAFs. SECURITY_WAF_VISIBLE and SECURITY_WAF are
  // both required for this flag to be turned on
  SECURITY_WAF_BOTS: 'SECURITY_WAF_BOTS',
  // This enables new caching/traffic metrics provider Edge Query.
  RUM_EDGE_QUERY: 'RUM_EDGE_QUERY',
  EDGECAST_SYNC: 'EDGECAST_SYNC',
  PURGE_BY_CACHE_HASH: 'PURGE_BY_CACHE_HASH'
}

export default FEATURES
