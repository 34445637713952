import { cyan } from '@material-ui/core/colors'
import { Overrides } from '@material-ui/core/styles/overrides'
import { Colors } from '../colors'
import { customColors, lightCacheStatusColors } from '../customColors'

export const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      a: {
        color: cyan['700']
      },
      '#root': {
        backgroundColor: customColors.White
      },
      '.statusGoodText': {
        color: `${lightCacheStatusColors.green} !important`
      },
      '.statusFairText': { color: `${lightCacheStatusColors.yellowText} !important` },
      '.statusPoorText': { color: `${lightCacheStatusColors.red} !important` }
    }
  },
  MuiTableHead: {
    root: {
      backgroundColor: Colors.grey['t98']
    }
  },
  MuiDivider: {
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    }
  }
}
