import React, { MouseEvent } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    fontSize: '1.25rem'
  },
  sizeSmall: {
    fontSize: '0.8rem'
  }
}))

interface Props {
  onClick: (event: MouseEvent) => void
  size?: 'medium' | 'small'
}

export function ClearButton({ onClick, size = 'medium' }: Props) {
  const classes = useStyles()

  return (
    <IconButton aria-label="clear" classes={classes} onClick={onClick} size={size}>
      <ClearIcon fontSize="inherit" />
    </IconButton>
  )
}
