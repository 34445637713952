import { cyan, grey } from '@material-ui/core/colors'
import { Overrides } from '@material-ui/core/styles/overrides'
import { customColors, darkCacheStatusColors } from '../customColors'

export const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      a: {
        color: cyan['A200']
      },
      '#root': {
        backgroundColor: customColors.EerieBlack
      },
      '.statusGoodText': {
        color: `${darkCacheStatusColors.green} !important`
      },
      '.statusFairText': { color: `${darkCacheStatusColors.yellowText} !important` },
      '.statusPoorText': { color: `${darkCacheStatusColors.red} !important` }
    }
  },
  MuiTableHead: {
    root: {
      backgroundColor: grey[700]
    }
  },
  MuiDivider: {
    root: {
      backgroundColor: 'rgba(255, 255, 255, 0.12)'
    }
  }
}
