import React from 'react'
import MuiFormGroup from '@material-ui/core/FormGroup'

interface Props {
  /** The content of the component. */
  children: JSX.Element | JSX.Element[]
  /** Display group of elements in a compact row. */
  row?: boolean
}

/**
 * Component for grouping form elements.
 */
export function FormGroup({ children, row = false }: Props) {
  return <MuiFormGroup row={row}>{children}</MuiFormGroup>
}
