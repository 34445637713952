import { TableHead as MuiTableHead, TableSortLabel } from '@material-ui/core'
import { TableCell, TableRow } from '../../atoms'
import { ColumnOrderOptions } from './constants'
import { TTableHeadProps } from './types'

export const TableHead = ({ columns, order, orderBy, onRequestSort }: TTableHeadProps) => (
  <MuiTableHead>
    <TableRow>
      {columns?.map((column) => (
        <TableCell key={column.id} sortDirection={orderBy === column.id ? order : false}>
          {onRequestSort ? (
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : ColumnOrderOptions.asc}
              onClick={(event) => onRequestSort(event, column.id)}
            >
              {column.label}
            </TableSortLabel>
          ) : (
            <>{column.label}</>
          )}
        </TableCell>
      ))}
    </TableRow>
  </MuiTableHead>
)
