import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'

export const useUnauthenticatedRedirect = ({ currentUser, loading, bypass }) => {
  const {
    query: { err },
    replace,
    pathname
  } = useRouter()
  const [redirected, setRedirected] = useState(false)

  useEffect(() => {
    if (bypass || redirected || currentUser || loading) return

    const loginPath = '/login'
    const signupPath = '/signup'

    const hasLoggedInBefore = Object.keys(Cookies.get()).some((cookie) =>
      cookie.includes('lastVisitedTeam')
    )

    const currentUrl = new URL(window.location.href)
    let newQuery = {}
    if (err) {
      newQuery.err = err
    }
    // The err param is handled separately by useCaptureAuthError to display error notifications
    currentUrl.searchParams.delete('err')
    newQuery.redirectTo = currentUrl.pathname + currentUrl.search

    replace({ pathname: hasLoggedInBefore ? loginPath : signupPath, query: newQuery })
    setRedirected(true)
  }, [bypass, redirected, currentUser, loading, err, pathname, replace])
}
