import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

export const LinkButton = withStyles((theme) => ({
  root: {
    // @ts-ignore
    color: theme.palette.linkButtonColor.main,
    fontWeight: 500,
    fontSize: '0.875rem',

    ['& svg']: {
      fontSize: '1.125rem !important' // TODO: update when got more sizes in design
    }
  }
}))(Button)
