import React, { useContext } from 'react'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import { createTheme } from 'ui/theme'
import DarkModeContext from './DarkModeContext'

export default function ThemeProvider({ children }) {
  const { darkMode } = useContext(DarkModeContext)
  const theme = createTheme(darkMode)

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}
