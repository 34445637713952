import { pink } from '@material-ui/core/colors'
import { customColors } from '../customColors'

export const general = {
  palette: {
    type: 'dark',
    primary: {
      light: pink['A100']
    },
    success: {
      light: pink['A100']
    },
    text: {
      primary: customColors.White,
      secondary: customColors.White
    },
    background: {
      paper: customColors.Jet
    },
    action: {
      active: customColors.White,
      disabledBackground: customColors.GraniteGray
    }
  },
  typography: {
    h1: {
      color: customColors.White
    },
    subtitle1: {
      color: customColors.White
    },
    body1: {
      color: customColors.White
    },
    body2: {
      color: customColors.White
    },
    caption: {
      color: customColors.White
    }
  }
}
