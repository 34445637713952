import { Notifier } from '@airbrake/browser'

// Report to the Layer0 Developer Console - Browser project in Airbrake
// https://moovweb.airbrake.io/projects/326154
const airbrake = new Notifier({
  projectId: 326154,
  projectKey: '2e9076590aca346097b0832c64662473',
  environment: process.env['ENVIRONMENT_NAME']
})

// This prevents non-production errors from being sent to AIrbrake:
airbrake.addFilter((notice) => (process.env['ENVIRONMENT_NAME'] !== 'development' ? notice : null))

export default airbrake
