import { customColors, darkCacheStatusColors } from '../customColors'

export const custom = {
  palette: {
    cacheStatus: darkCacheStatusColors,
    borderColor: {
      main: customColors.GraniteGray
    },
    linkButtonColor: {
      main: '#27AB66' // TODO: get it later from DS
    }
  },
  typography: {
    code: {
      backgroundColor: 'rgb(29, 31, 33)'
    }
  }
}
