import { IconButton } from '@material-ui/core'
import { DragIndicator } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow } from '../../atoms'
import { TTableDraggableRow } from './types'

const useStyles = makeStyles(() => ({
  row: {
    cursor: 'pointer'
  }
}))

export const TableDraggableRow = ({
  id,
  onClick,
  draggableProvided,
  children
}: TTableDraggableRow) => {
  const classes = useStyles()
  return (
    <TableRow
      {...draggableProvided.draggableProps}
      ref={draggableProvided.innerRef}
      hover={true}
      tabIndex={-1}
      key={id}
      onClick={() => (onClick ? onClick(id) : null)}
      classes={{
        root: classes.row
      }}
    >
      {/* columns needs to be fixed to avoid bad UX - https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/patterns/tables.md */}
      <TableCell>
        <IconButton {...draggableProvided.dragHandleProps}>
          <DragIndicator />
        </IconButton>
      </TableCell>
      {/* should be a Cell(s) */}
      {children}
    </TableRow>
  )
}
