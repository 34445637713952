import { ReactElement } from 'react'

export enum RulesActionPanelBadge {
  if = 'if',
  elseIf = 'else if'
}

export type TRulesActionPanelBadge = RulesActionPanelBadge.if | RulesActionPanelBadge.elseIf

export type TRulesActionPanel = {
  ruleName: string | ReactElement
  ruleTypeLabel?: string | ReactElement
  badge?: TRulesActionPanelBadge
  onDelete?: () => void
  onEdit?: () => void
}
