import React from 'react'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

/**
 * Displays a friendly error message
 * @param {*} param0
 */
export default function ShowError({ error, message }) {
  if (!error) {
    return null
  } else {
    return message
  }
}

ShowError.propTypes = {
  error: PropTypes.object.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

ShowError.defaultProps = {
  message: (
    <Typography>An unknown error occurred while attempting to process your request.</Typography>
  )
}
