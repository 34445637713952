import { useContext, useEffect } from 'react'
import { useRouter } from 'next/router'
import { HOME_SNAPSHOT_VIEW } from '../build/BuildHomeSnapshotPanel'
import { gql, useMutation, useQuery } from '@apollo/client'
import produce from 'immer'
import get from 'lodash/get'
import AppContext from '../AppContext'
import { currentUserQuery } from '../auth/authQueries'
import { TEAM_LIST_VIEW } from '../user/userQueries'
import { createArrayUpdater, useNotifications } from '../util'

const LATEST_BUILD_VIEW = gql`
  fragment LatestBuildView on Build {
    id
    number
    status
    createdAt
    permalinkUrl
    xdnVersion
    homeSnapshot {
      ...homeSnapshotView
    }
    user {
      email
    }
  }
  ${HOME_SNAPSHOT_VIEW}
`

export const SITE_VIEW = gql`
  fragment SiteView on Site {
    id
    slug
    githubUrl
    framework
    siteType
    cert {
      id
    }
    skipFastly
    hasRumData
    productionEnvironment {
      name
    }
    onboarding {
      id
      data
      completedAt
      dismissedAt
    }
    activeDomains {
      name
      environment {
        name
        production
      }
    }
    latestBuild {
      ...LatestBuildView
    }
    createdAt
    updatedAt
  }
  ${LATEST_BUILD_VIEW}
`

const TEAM_VIEW = gql`
  fragment TeamView on Team {
    id
    gid
    name
    slug
    tier
    personal
    fastlyToken
    fastlyTokenStatus
    fastlyTokenError
    xdnRegion {
      id
      name
      primaryAwsRegion
      secondaryAwsRegion
      fastlyShieldCode
    }
    edgeXdnRegion {
      id
      name
      fastlyShieldCode
    }
    organization {
      id
      name
      slug
    }
    members {
      role
      user {
        id
        email
        confirmedAt
      }
    }
    sites(first: 1000, orderBy: [{ slug: asc }]) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ...SiteView
      }
    }
    awsAccount {
      id
      maxConcurrency
      signInUrl
      provisionerVersion
    }
    edgecastId
    edgecastAdminId
    release2Enabled
    trustedDomains
  }
  ${SITE_VIEW}
`

const createTeamMutation = gql`
  mutation createTeam($name: String!) {
    createTeam(team: { name: $name }) {
      team {
        ...TeamListView
      }
      userErrors {
        path
        message
      }
    }
  }
  ${TEAM_LIST_VIEW}
`

export const useCreateTeamMutation = () => {
  const [mutate, mutationResult] = useMutation(createTeamMutation)

  return [
    async (variables) => {
      const { data } = await mutate({
        variables,
        update: (
          cache,
          {
            data: {
              createTeam: { team }
            }
          }
        ) => {
          if (team) {
            cache.updateQuery(
              { query: currentUserQuery },
              produce((draft) => {
                draft.currentUser.members.nodes.push({
                  __typename: 'Member',
                  team,
                  role: 'super_admin'
                })
              })
            )
          }
        }
      })
      return data.createTeam
    },
    mutationResult
  ]
}

export const getTeam = gql`
  query team($slug: String!) {
    team(slug: $slug) {
      ...TeamView
    }
    teamFeatures(slug: $slug) {
      name
      minXdnVersion
      minSubaccountVersion
    }
  }
  ${TEAM_VIEW}
`

export const updateTeam = gql`
  mutation updateTeam($team: UpdateTeamAttributes!) {
    updateTeam(team: $team) {
      team {
        ...TeamView
      }
      userErrors {
        path
        message
      }
    }
  }
  ${TEAM_VIEW}
`
const deleteTeam = gql`
  mutation deleteTeam($id: ID!) {
    deleteTeam(id: $id)
  }
`

export const useDeleteTeamMutation = () => {
  const [mutate, mutationResult] = useMutation(deleteTeam)

  return [
    async (variables) => {
      const { data } = await mutate({
        variables
      })
      return data.deleteTeam
    },
    mutationResult
  ]
}

export const deleteTeamMembers = gql`
  mutation deleteTeamMembers($userIds: [ID!]!, $teamId: ID!) {
    deleteTeamMembers(userIds: $userIds, teamId: $teamId) {
      ...TeamView
    }
  }
  ${TEAM_VIEW}
`

export const updateTeamMemberRole = gql`
  mutation updateTeamMemberRole($userId: ID!, $teamId: ID!, $role: RoleEnum!) {
    changeRole(userId: $userId, teamId: $teamId, role: $role)
  }
`

export const inviteTeamMember = gql`
  mutation inviteTeamMembers($members: [InviteMembersInput!]!, $teamId: ID!) {
    inviteTeamMembers(members: $members, teamId: $teamId) {
      team {
        ...TeamView
      }
      userErrors {
        path
        message
      }
    }
  }
  ${TEAM_VIEW}
`

const sitesSubscription = gql`
  subscription getTeamSitesSubscription($teamId: ID!) {
    teamSitesUpdated(teamId: $teamId) {
      new {
        ...SiteView
      }
      updated {
        ...SiteView
      }
      deleted
    }
  }
  ${SITE_VIEW}
`

const teamDeletedSubscription = gql`
  subscription getDeletedTeamSubscription($teamId: ID!) {
    teamDeleted(teamId: $teamId) {
      deleted
    }
  }
`

const teamUpdatedSubscription = gql`
  subscription teamUpdatedSubscription($teamId: ID!) {
    teamUpdated(teamId: $teamId) {
      new {
        ...TeamView
      }
      updated {
        ...TeamView
      }
    }
  }
  ${TEAM_VIEW}
`

export const useGetTeamQuery = (teamSlug, options = {}) => {
  const {
    push,
    query: { siteSlug }
  } = useRouter()
  const notification = useNotifications({ closeButton: true })
  const { subscribeToMore, refetch, client, data, loading, error } = useQuery(getTeam, {
    variables: {
      slug: teamSlug
    },
    // Loading state would stay false on slug change otherwise
    notifyOnNetworkStatusChange: true,
    ...options
  })

  const team = data && data.team
  const teamFeatures = (data && data.teamFeatures) || []

  useEffect(() => {
    if (!team?.id) return
    const subscription = subscribeToMore({
      document: sitesSubscription,
      variables: { teamId: team.id },
      updateQuery: (store, { subscriptionData }) => {
        const result = subscriptionData.data.teamSitesUpdated
        // We refetch log entries if site was deleted
        // Log entries are cleaned up by db itself and not tracked by subscriptions
        if (result.deleted) {
          // If any user is on deleted site, he will be redirected to team
          const currentSite =
            get(store, 'team.sites.nodes', []).find((site) => site.id === result.deleted[0]) || {}
          if (siteSlug && currentSite.slug === siteSlug) {
            push(`/${teamSlug}`)
            notification.info(`${siteSlug} was deleted`, { autoHideDuration: 10000 })
          }
          refetch()

          return store
        } else {
          return createArrayUpdater(
            (prev) => prev.team.sites,
            ({ subscriptionData: { data } }) => data.teamSitesUpdated
          )(store, { subscriptionData })
        }
      }
    })

    return subscription
    // `push` not included since its reference changes between renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team?.id, siteSlug, teamSlug, refetch, notification, subscribeToMore])

  useEffect(() => {
    if (team) {
      const cleanups = [
        subscribeToMore({
          document: teamUpdatedSubscription,
          variables: { teamId: team.id },
          updateQuery: createArrayUpdater(
            (prev) => prev.awsAccounts,
            ({ subscriptionData: { data } }) => data.teamUpdated
          )
        }),
        subscribeToMore({
          document: teamDeletedSubscription,
          variables: { teamId: team.id },
          updateQuery: (store) => {
            client.cache.updateQuery(
              { query: currentUserQuery },
              produce((draft) => {
                draft.currentUser.members.nodes = draft.currentUser.members.nodes.filter(
                  (node) => node.team.id !== team.id
                )
              })
            )
            push('/')
            notification.info(`${team.name} was deleted`, { autoHideDuration: 10000 })

            return store
          }
        })
      ]
      return () => cleanups.forEach((cleanup) => cleanup())
    }
  }, [team && team.id])

  return { refetch, team, loading, error, teamFeatures }
}

const updateFastlyToken = gql`
  mutation updateFastlyToken($id: ID!, $fastlyToken: String!) {
    updateFastlyToken(id: $id, fastlyToken: $fastlyToken) {
      team {
        ...TeamView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${TEAM_VIEW}
`

export const useUpdateFastlyTokenMutation = () => {
  const [mutate, mutationResult] = useMutation(updateFastlyToken)

  return [
    async (id, fastlyToken) => {
      const { data } = await mutate({
        variables: { id, fastlyToken }
      })
      return data.updateFastlyToken
    },
    mutationResult
  ]
}

const getFastlyDomains = gql`
  query fastlyDomains($teamId: ID!) {
    fastlyDomains(teamId: $teamId) {
      id
      name
      environment {
        id
        name
      }
      site {
        id
        slug
      }
    }
  }
`

export const useGetFastlyDeprecatedDomains = () => {
  const { currentTeam } = useContext(AppContext)
  const teamId = currentTeam?.id

  const { data } = useQuery(getFastlyDomains, { variables: { teamId }, skip: !teamId })

  // Fastly domains for BYOF are not deprecated
  if (currentTeam?.byof) {
    return []
  }

  if (currentTeam?.skipFastly) {
    return []
  }

  return data?.fastlyDomains || []
}

export const migrateTeamToV7Mutation = gql`
  mutation migrateTeamToV7($teamId: ID!, $v7ApiKey: String!, $inviteEmails: [String!]) {
    migrateTeamToV7(teamId: $teamId, v7ApiKey: $v7ApiKey, inviteEmails: $inviteEmails) {
      success
      userErrors {
        message
        path
      }
    }
  }
`

export const useMigrateTeamToV7Mutation = () => {
  const [mutate] = useMutation(migrateTeamToV7Mutation)

  return async (variables) => {
    const { data } = await mutate({ variables })
    return data.migrateTeamToV7
  }
}
