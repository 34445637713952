import { grey, purple, red, yellow } from '@material-ui/core/colors'
import { alpha as fade } from '@material-ui/core/styles'
import { customColors, primaryColors } from '../customColors'

export const general = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600
    }
  },
  palette: {
    common: {
      black: customColors.Jet,
      white: customColors.White
    },
    primary: primaryColors,
    secondary: {
      main: customColors.SilverSand,
      light: purple[400],
      dark: customColors.BleuDeFrance,
      contrastText: customColors.White
    },
    brand: {
      primary: grey[800],
      secondary: '#DD549F',
      contrastText: grey[50]
    },
    danger: {
      main: red['A400'],
      light: red[300],
      dark: red[700],
      contrastText: '#fff'
    },
    error: {
      main: customColors.PastelRed
    },
    success: primaryColors,
    warning: {
      main: yellow[500],
      light: yellow[300],
      dark: yellow[700],
      contrastText: '#fff'
    },
    text: {
      disabled: customColors.SilverSand
    },
    background: {
      paper: customColors.Jet
    },
    action: {
      disabled: customColors.SilverSand,
      hover: fade(primaryColors.main, 0.05),
      selected: fade(primaryColors.main, 0.1)
    }
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
    h1: {
      fontSize: '2rem',
      lineHeight: 1.2,
      margin: '0.5rem 0 2rem'
    },
    h2: {
      fontSize: '1.75rem',
      margin: '15px 0 15px'
    },
    h3: {
      fontSize: '1.5rem'
    },
    h4: {
      fontSize: '1.25rem'
    },
    subtitle1: {
      fontSize: '1.25rem',
      lineHeight: 1.2
    },
    body2: {
      fontSize: '0.875rem'
    },
    button: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.2,
      textTransform: 'none'
    },
    caption: {
      lineHeight: 1.33
    }
  },
  shape: {
    borderRadius: 3
  },
  overrides: {}
}
