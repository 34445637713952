import { useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'

export const CERT_VIEW = gql`
  fragment CertView on Cert {
    id
    primaryCert
    intermediateCert
    privateKey
    status
    generated
    expiration
    error
    firstActivated
    lastRenewed
    missingDomains
    cnSanNames
    serial
  }
`

const getCert = gql`
  query getCert($siteId: ID!) {
    cert(siteId: $siteId) {
      ...CertView
    }
  }
  ${CERT_VIEW}
`

const certSubscription = gql`
  subscription certSubscription($siteId: ID!) {
    siteCertUpdated(siteId: $siteId) {
      new {
        ...CertView
      }
      updated {
        ...CertView
      }
      deleted
    }
  }
  ${CERT_VIEW}
`

export const useGetCertQuery = (siteId) => {
  const queryResults = useQuery(getCert, {
    variables: { siteId }
  })

  const { subscribeToMore } = queryResults

  useEffect(() => {
    if (siteId) {
      return subscribeToMore({
        document: certSubscription,
        variables: { siteId },
        updateQuery: (store, { subscriptionData }) => {
          if (subscriptionData.data.siteCertUpdated.new) {
            return {
              cert: subscriptionData.data.siteCertUpdated.new[0]
            }
          }
          return store
        }
      })
    }
  }, [siteId])

  return queryResults
}

const uploadCertMutation = gql`
  mutation uploadCert($certAttributes: UploadCertAttributes!) {
    uploadCert(certAttributes: $certAttributes) {
      cert {
        ...CertView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CERT_VIEW}
`

export const useUploadCert = () => {
  const [mutate, mutationResult] = useMutation(uploadCertMutation)

  return [
    async (certAttributes) => {
      const { data } = await mutate({
        variables: { certAttributes }
      })
      return data.uploadCert
    },
    mutationResult
  ]
}

const generateCertMutation = gql`
  mutation generateCert($siteId: ID!) {
    generateCert(siteId: $siteId) {
      cert {
        ...CertView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CERT_VIEW}
`

export const useGenerateCert = () => {
  const [mutate, mutationResult] = useMutation(generateCertMutation)

  return [
    async (siteId) => {
      const { data } = await mutate({
        variables: { siteId }
      })
      return data.generateCert
    },
    mutationResult
  ]
}

const deleteCert = gql`
  mutation deleteCert($id: ID!) {
    deleteCert(id: $id)
  }
`

export const useDeleteCert = () => {
  const [mutate] = useMutation(deleteCert)

  return (certId) => mutate({ variables: { id: certId } })
}
