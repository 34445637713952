import { gql, useQuery } from '@apollo/client'
import { TEAM_LIST_VIEW } from '../user/userQueries'

const ORGANIZATION_VIEW = gql`
  fragment OrganizationView on Organization {
    id
    name
    slug
    members {
      role
      user {
        id
        email
        confirmedAt
      }
    }
    teams {
      nodes {
        ...TeamListView
      }
    }
  }
  ${TEAM_LIST_VIEW}
`

export const getOrganization = gql`
  query organization($slug: String!) {
    organization(slug: $slug) {
      ...OrganizationView
    }
  }
  ${ORGANIZATION_VIEW}
`

export const deleteOrganizationMembers = gql`
  mutation deleteOrganizationMembers($userIds: [ID!]!, $organizationId: ID!) {
    deleteOrganizationMembers(userIds: $userIds, organizationId: $organizationId) {
      ...OrganizationView
    }
  }
  ${ORGANIZATION_VIEW}
`

export const updateOrganizationMemberRole = gql`
  mutation updateOrganizationMemberRole($userId: ID!, $organizationId: ID!, $role: RoleEnum!) {
    updateOrganizationMemberRole(userId: $userId, organizationId: $organizationId, role: $role)
  }
`

export const addOrganizationMember = gql`
  mutation addOrganizationMembers($members: [InviteMembersInput!]!, $organizationId: ID!) {
    addOrganizationMembers(members: $members, organizationId: $organizationId) {
      ...OrganizationView
    }
  }
  ${ORGANIZATION_VIEW}
`

export const useGetOrganizationQuery = (organizationSlug, options = {}) => {
  const { data, loading, error, refetch } = useQuery(getOrganization, {
    variables: {
      slug: organizationSlug
    },
    // Loading state would stay false on slug change otherwise
    notifyOnNetworkStatusChange: true,
    ...options
  })

  const organization = data && data.organization

  return { refetch, organization, loading, error }
}
