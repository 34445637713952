import { useContext, useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import AppContext from '../AppContext'
import { getEnvironments } from '../site/siteQueries'
import { ENV_VAR_VIEW } from './variables/environmentVariableQueries'

const ENVIRONMENT_BUILD_CARD_VIEW = gql`
  fragment EnvironmentBuildCardView on Build {
    id
    number
    xdnVersion
    status
    inProgress
    finishedAt
    environmentVersion {
      id
      version
    }
    branch {
      id
      name
    }
  }
`

const ENVIRONMENT_VERSION_LIST_VIEW = gql`
  fragment EnvironmentVersionListView on EnvironmentVersion {
    id
    version
    draft
    waf {
      id
      name
      activeVersion {
        version
        configurationJson
      }
    }
    botManagement {
      domainId
    }
  }
`

export const DOMAIN_VIEW = gql`
  fragment DomainView on Domain {
    id
    name
    createdAt
    isApex
    environment {
      production
    }
    cert {
      expiration
      status
      lastRenewed
      firstActivated
      generated
      missingDomains
    }
  }
`

const PUBLIC_DOMAIN_VIEW = gql`
  fragment PublicDomainView on PublicDomain {
    id
    name
    createdAt
    isApex
  }
`
const VALIDATE_DOMAIN_VIEW = gql`
  fragment ValidateDomainView on PublicDomain {
    ...PublicDomainView
    validTlsCaa
    validTlsCname
    validDns
  }
  ${PUBLIC_DOMAIN_VIEW}
`

const ENVIRONMENT_VERSION_VIEW = gql`
  fragment EnvironmentVersionView on EnvironmentVersion {
    ...EnvironmentVersionListView
    versionNotes
    httpRequestLogging
    domains {
      nodes {
        ...PublicDomainView
      }
    }
    splitTestingRules
    ipAllowList
    ipBlockList
    countryBlockList
    redirectLocations
    redirectDefaultStatus
    tooManyRedirects
    preserveCache
    environmentVariables {
      nodes {
        ...EnvVarView
      }
    }
    waf {
      id
    }
    botManagement {
      id
      domainId
      secretKey
      apiKey
      mitigatedRoutes
      createdAt
      updatedAt
    }
  }
  ${ENVIRONMENT_VERSION_LIST_VIEW}
  ${ENV_VAR_VIEW}
  ${PUBLIC_DOMAIN_VIEW}
`

const ENVIRONMENT_VIEW = gql`
  fragment EnvironmentView on Environment {
    id
    name
    default
    limelightDefaultDomainName
    limelightDnsDomainName
    limelightIpWhitelist
    limelightApexIps
    fastlyDefaultDomainName
    fastlyDnsDomainName
    fastlyIpWhitelist
    allowlistIps {
      ipAddresses
      sourceName
    }
    activeUrls
    splitTestingDestinations
    canMembersDeploy
    production
    site {
      maxMemorySize
      cert {
        id
      }
    }
    awsAccount {
      id
      name
      accessLogsBucketName
      free
      accessLogsAwsAccessKeyId
      accessLogsAwsSecretAccessKey
      logDestinationS3Enabled
    }
    environmentVersions(orderBy: [{ version: desc }]) {
      nodes {
        ...EnvironmentVersionListView
      }
    }
    activeVersion {
      ...EnvironmentVersionListView
    }
    latestBuild {
      ...EnvironmentBuildCardView
    }
    activeBuild {
      ...EnvironmentBuildCardView
    }
    activeDomains {
      ...DomainView
    }
  }
  ${ENVIRONMENT_VERSION_LIST_VIEW}
  ${ENVIRONMENT_BUILD_CARD_VIEW}
  ${DOMAIN_VIEW}
`

const getEnvironment = gql`
  query environmentByName($teamSlug: String!, $siteSlug: String!, $name: String!) {
    environmentByName(teamSlug: $teamSlug, siteSlug: $siteSlug, name: $name) {
      ...EnvironmentView
    }
  }
  ${ENVIRONMENT_VIEW}
`

export const validateDomain = gql`
  query domain($domainId: ID!) {
    domain(domainId: $domainId) {
      ...ValidateDomainView
    }
  }
  ${VALIDATE_DOMAIN_VIEW}
`

const environmentUpdatedSubscription = gql`
  subscription environmentUpdated($environmentId: ID!) {
    environmentUpdated(environmentId: $environmentId) {
      updated {
        ...EnvironmentView
      }
    }
  }
  ${ENVIRONMENT_VIEW}
`

export const useGetEnvironment = (variables) => {
  const { subscribeToMore, loading, data, error } = useQuery(getEnvironment, {
    variables,
    // because subscription is inactive while un-mounted, we refresh on remount
    fetchPolicy: 'cache-and-network'
  })

  const environment = data && data.environmentByName

  useEffect(() => {
    if (!environment) return

    return subscribeToMore({
      document: environmentUpdatedSubscription,
      variables: { environmentId: environment.id }
    })
  }, [environment && environment.id])

  return { loading, environment: data && data.environmentByName, error }
}

export const updateEnvironment = gql`
  mutation updateEnvironment($environment: UpdateEnvironmentAttributes!) {
    updateEnvironment(environment: $environment) {
      environment {
        ...EnvironmentView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${ENVIRONMENT_VIEW}
`

export const useUpdateEnvironmentMutation = () => {
  const [mutate, mutationResult] = useMutation(updateEnvironment)

  return [
    async (environment) => {
      const { data } = await mutate({
        variables: { environment }
      })

      return data.updateEnvironment
    },
    mutationResult
  ]
}

export const getEnvironmentVersion = gql`
  query environmentVersion($id: ID!) {
    environmentVersion(id: $id) {
      ...EnvironmentVersionView
    }
  }
  ${ENVIRONMENT_VERSION_VIEW}
`

export const useGetEnvironmentVersion = (variables) => {
  const { loading, data, error, refetch } = useQuery(getEnvironmentVersion, {
    variables,
    // Always refetch environment data, until we implement subscription
    fetchPolicy: 'network-only',
    // Skip request when the ID is not provided
    skip: variables.id === undefined
  })

  return {
    loading,
    environmentVersion: data && data.environmentVersion,
    error,
    refetch
  }
}

export const updateEnvironmentVersionDraftMutation = gql`
  mutation updateEnvironmentVersionDraft($environmentVersion: UpdateEnvironmentVersionAttributes!) {
    updateEnvironmentVersionDraft(environmentVersion: $environmentVersion) {
      environmentVersion {
        ...EnvironmentVersionView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${ENVIRONMENT_VERSION_VIEW}
`

export const useUpdateEnvironmentVersionDraft = () => {
  const [mutate, mutationResult] = useMutation(updateEnvironmentVersionDraftMutation)

  return [
    async (environmentVersion) => {
      const { data } = await mutate({
        variables: { environmentVersion }
      })

      return data.updateEnvironmentVersionDraft
    },
    mutationResult
  ]
}

const createEnvironment = gql`
  mutation createEnvironment($environment: CreateEnvironmentAttributes!, $copyEnvironment: ID) {
    createEnvironment(environment: $environment, copyEnvironment: $copyEnvironment) {
      environment {
        ...EnvironmentView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${ENVIRONMENT_VIEW}
`

export const useCreateEnvironmentMutation = () => {
  const [mutate, mutationResult] = useMutation(createEnvironment)

  return [
    async (siteId, copyEnvId, { name, canMembersDeploy, production }) => {
      const { data } = await mutate({
        variables: {
          environment: { siteId, name, canMembersDeploy, production },
          copyEnvironment: copyEnvId
        },
        // If it works, refresh the list
        refetchQueries: [{ query: getEnvironments, variables: { siteId } }],
        // Need to await that list is refreshed as usually the next step
        // is to navigate to the Environment page
        awaitRefetchQueries: true
      })

      return data.createEnvironment
    },
    mutationResult
  ]
}

const deleteEnvironment = gql`
  mutation deleteEnvironment($id: ID!) {
    deleteEnvironment(id: $id)
  }
`

export const useDeleteEnvironmentMutation = () => {
  const [mutate, mutationResult] = useMutation(deleteEnvironment)
  const { currentSite } = useContext(AppContext)

  return [
    async (id) => {
      const { data } = await mutate({
        variables: { id },
        // If it works, refresh the list. Suboptimal but lighter code
        refetchQueries: [{ query: getEnvironments, variables: { siteId: currentSite.id } }],
        awaitRefetchQueries: true
      })
      return data.deleteEnvironment
    },
    mutationResult
  ]
}

const setProductionEnvironment = gql`
  mutation setProductionEnvironment($id: ID!) {
    setProductionEnvironment(id: $id) {
      userErrors {
        message
        path
      }
    }
  }
`

export const useSetProductionEnvironment = () => {
  const [mutate, mutationResult] = useMutation(setProductionEnvironment)

  return [
    async (id) => {
      const { data } = await mutate({
        variables: { id }
      })
      return data.setProductionEnvironment
    },
    mutationResult
  ]
}

const activateEnvironmentVersion = gql`
  mutation activateEnvironmentVersion($id: ID!) {
    activateEnvironmentVersion(id: $id) {
      userErrors {
        message
        path
      }
    }
  }
`

export const useActivateEnvironmentVersion = () => {
  const [mutate, mutationResult] = useMutation(activateEnvironmentVersion)
  const { currentTeam, currentSite, currentEnvironment } = useContext(AppContext)

  return [
    async (environmentVersionId) => {
      const { data } = await mutate({
        variables: { id: environmentVersionId },
        // TODO: refator duplicated refresh code
        refetchQueries: [
          {
            query: getEnvironment,
            variables: {
              teamSlug: currentTeam.slug,
              siteSlug: currentSite.slug,
              name: currentEnvironment.name
            }
          }
        ],
        awaitRefetchQueries: true
      })
      return data.activateEnvironmentVersion
    },
    mutationResult
  ]
}

const createEnvironmentVersionDraft = gql`
  mutation createEnvironmentVersionDraft($environmentVersionId: ID!) {
    createEnvironmentVersionDraft(environmentVersionId: $environmentVersionId) {
      environmentVersion {
        ...EnvironmentVersionListView
      }
    }
  }
  ${ENVIRONMENT_VERSION_LIST_VIEW}
`

export const useCreateEnvironmentVersionDraft = () => {
  const [mutate, mutationResult] = useMutation(createEnvironmentVersionDraft)
  const { currentTeam, currentSite, currentEnvironment } = useContext(AppContext)

  return [
    async (environmentVersionId) => {
      const { data } = await mutate({
        variables: { environmentVersionId },
        // TODO: refator duplicated refresh code
        refetchQueries: [
          {
            query: getEnvironment,
            variables: {
              teamSlug: currentTeam.slug,
              siteSlug: currentSite.slug,
              name: currentEnvironment.name
            }
          }
        ],
        awaitRefetchQueries: true
      })
      return data.createEnvironmentVersionDraft.environmentVersion
    },
    mutationResult
  ]
}

const deleteEnvironmentVersionDraft = gql`
  mutation deleteEnvironmentVersionDraft($environmentVersionId: ID!) {
    deleteEnvironmentVersionDraft(environmentVersionId: $environmentVersionId) {
      userErrors {
        message
        path
      }
    }
  }
`

export const useDeleteEnvironmentVersionDraft = () => {
  const [mutate, mutationResult] = useMutation(deleteEnvironmentVersionDraft)
  const { currentTeam, currentSite, currentEnvironment } = useContext(AppContext)

  return [
    async (environmentVersionId) => {
      const { data } = await mutate({
        variables: { environmentVersionId },
        // TODO: refator duplicated refresh code
        refetchQueries: [
          {
            query: getEnvironment,
            variables: {
              teamSlug: currentTeam.slug,
              siteSlug: currentSite.slug,
              name: currentEnvironment.name
            }
          }
        ],
        awaitRefetchQueries: true
      })
      return data.deleteEnvironmentVersionDraft
    },
    mutationResult
  ]
}

export const useUploadRedirectsMutation = () =>
  useMutation(gql`
    mutation uploadRedirects($environmentVersionId: ID!, $file: Upload!, $override: Boolean!) {
      uploadEnvironmentRedirects(
        environmentVersionId: $environmentVersionId
        file: $file
        override: $override
      ) {
        environmentVersion {
          ...EnvironmentVersionView
        }
        userErrors {
          message
          path
        }
      }
    }
    ${ENVIRONMENT_VERSION_VIEW}
  `)
