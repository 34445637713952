import { ChangeEvent } from 'react'
import MuiCheckbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import { Check, Remove } from '@material-ui/icons'
import clsx from 'clsx'
import Colors from '../Colors'
import {
  BaseControl,
  CommonControlDefaultProps,
  CommonControlProps
} from './components/BaseControl'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.common.black : theme.palette.common.white,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.shape.borderRadius,
    boxSizing: 'border-box',
    height: '1rem',
    width: '1rem',
    'input:disabled ~ &': {
      backgroundColor: theme.palette.action.disabledBackground,
      borderColor: theme.palette.action.disabled
    }
  },
  checkedIcon: {
    backgroundColor: theme.palette.secondary.main,
    border: 'none',
    color: theme.palette.common.white,
    position: 'relative'
  },
  svg: {
    height: '0.9rem',
    left: '0.05rem',
    position: 'absolute',
    top: '0.05rem',
    width: '0.9rem'
  }
}))

interface Props extends CommonControlProps {
  /** If `true`, the component is checked. */
  checked?: boolean
  /** If `true`, the component uses danger styling. */
  danger?: boolean
  /** If `true`, the component appears indeterminate. */
  indeterminate?: boolean
  /** Name attribute of the `input` element. */
  name?: string
  /** Callback fired when the state is changed. */
  onChange?: (event: ChangeEvent, newValue: any) => void
  /** The value of the component. The DOM API casts this to a string. The browser uses "on" as the default value. */
  value?: any
  /** Color style to apply */
  color?: string
}

/**
 * A boolean input.
 */
export function Checkbox({
  checked,
  danger = false,
  disabled,
  error,
  fullWidth,
  gutterBottom,
  helperText,
  indeterminate,
  label,
  name,
  onBlur,
  onFocus,
  onChange,
  required,
  value,
  color = Colors.EdgioBlue
}: Props) {
  const classes = useStyles()

  return (
    <BaseControl
      control={
        <MuiCheckbox
          checked={checked}
          checkedIcon={
            <span
              className={clsx(classes.icon, classes.checkedIcon)}
              style={{ backgroundColor: color }}
            >
              <Check className={classes.svg} />
            </span>
          }
          disabled={disabled}
          icon={<span className={classes.icon} />}
          indeterminate={indeterminate}
          indeterminateIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)}>
              <Remove className={classes.svg} />
            </span>
          }
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          size="small"
          value={value}
        />
      }
      danger={danger}
      error={error}
      fullWidth={fullWidth}
      gutterBottom={gutterBottom}
      helperText={helperText}
      label={label}
      required={required}
    />
  )
}

Checkbox.defaultProps = CommonControlDefaultProps
