import { ReactElement } from 'react'
import {
  Box,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  makeStyles
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { DragHorizontal, TrashIcon } from '../../../icons'
import { Theme } from '../../../theme'
import { IconButton } from '../../atoms'
import { TAccordionSummary } from './types'

const ACCORDION_SUMMARY_MIN_HEIGHT = 52

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    borderBottom: 'none',
    marginBottom: 0,
    borderRadius: theme.shape.borderRadius,
    minHeight: ACCORDION_SUMMARY_MIN_HEIGHT,

    '&$expanded': {
      minHeight: ACCORDION_SUMMARY_MIN_HEIGHT,
      borderBottom: `1px solid ${theme.palette.type === 'dark' ? '#101820' : '#ECEDEE'}`,
      backgroundColor: `${theme.palette.type === 'dark' ? '#101820' : '#FAFBFB'}`,
      borderRadius: '2px 2px 0px 0px' // TODO: replace hardcode
    }
  },
  expanded: {
    margin: 0
  },
  expandIcon: {
    padding: theme.spacing(0.25),
    margin: 0
  },
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,

    '&$expanded': {
      margin: 0
    }
  },
  accordionSummaryInfo: {
    display: 'inline-flex'
  },
  accordionTitle: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: `${theme.palette.type === 'dark' ? '#515A62' : '#687077'}`
  }
}))

export const AccordionSummary = ({
  title,
  draggableProvided,
  onDelete
}: TAccordionSummary): ReactElement<TAccordionSummary> => {
  const { root, accordionSummary, expanded, expandIcon, accordionTitle, icon } = useStyles()

  return (
    <MuiAccordionSummary
      expandIcon={<ExpandMoreIcon fontSize="small" className={icon} />}
      aria-controls="panel1c-content"
      id="panel1c-header"
      classes={{
        root: root,
        content: accordionSummary,
        expanded: expanded,
        expandIcon: expandIcon
      }}
    >
      <Box display="flex" alignItems="center">
        {draggableProvided && (
          <IconButton size="small" {...draggableProvided.dragHandleProps}>
            <DragHorizontal className={icon} />
          </IconButton>
        )}
        <Typography className={accordionTitle} variant="body2">
          {title}
          {onDelete && (
            <Box display="inline-flex" ml={1}>
              <IconButton size="small" onClick={onDelete}>
                <TrashIcon className={icon} />
              </IconButton>
            </Box>
          )}
        </Typography>
      </Box>
    </MuiAccordionSummary>
  )
}
