import React, { Ref, forwardRef } from 'react'
import { CardContent, CardHeader, Card as MuiCard } from '@material-ui/core'
import { alpha, makeStyles } from '@material-ui/core/styles'
import Colors from './Colors'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: `0px 1px 10px ${alpha(Colors.Black, 0.2)}`,
    height: '100%'
  }
}))

const useContentStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    '&:last-child': {
      paddingBottom: theme.spacing(4)
    }
  }
}))

const useHeaderStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'baseline',
    marginBottom: theme.spacing(-6),
    padding: theme.spacing(4)
  },
  action: {
    margin: 0,
    marginBottom: '-100%',
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2)
  },
  title: {
    borderBottom: `1px solid ${theme.palette.action.disabled}`,
    fontSize: '1.375rem',
    fontWeight: 500
  }
}))

export interface Props {
  /** The action to display in the card header. */
  action?: JSX.Element
  /** The content of the card. */
  children: JSX.Element | string
  /** ID used by hash navigation */
  id?: string
  /** The title of the card. */
  title?: string
}

export const Card = forwardRef(
  ({ action, children, id, title }: Props, ref: Ref<HTMLAnchorElement>) => {
    const classes = useStyles()
    const contentClasses = useContentStyles()
    const headerClasses = useHeaderStyles()

    return (
      <MuiCard classes={classes} id={id} ref={ref}>
        {title && <CardHeader action={action} classes={headerClasses} title={title} />}
        <CardContent classes={contentClasses}>{children}</CardContent>
      </MuiCard>
    )
  }
)

Card.displayName = 'Card'
