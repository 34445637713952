import { light as defaultPalette } from '@material-ui/core/styles/createPalette'

export const overrides = {
  MuiCssBaseline: {
    '@global': {
      a: {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      },
      '#root': {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      },
      '#root .App': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      },
      '#root .App main': {
        flex: 1,
        flexDirection: 'column',
        '& code': {
          padding: '3px 5px',
          borderWidth: 1,
          borderStyle: 'solid',
          whiteSpace: 'pre'
        }
      },
      [`input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration`]: {
        '-webkit-appearance': 'none'
      },
      [`input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus`]: {
        border: 'none',
        borderRadius: '0 !important'
      }
    }
  },
  MuiToolbar: {
    gutters: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  MuiCardHeader: {
    root: {
      paddingBottom: 0
    }
  },
  MuiTableHead: {
    root: {
      border: `1px solid ${defaultPalette.divider}`
    }
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 14
    }
  },
  MuiMenuItem: {
    root: {
      minWidth: 90
    }
  },
  MuiSnackbarContent: {
    message: {
      color: 'white'
    }
  },
  MuiPaper: {
    elevation1: {
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2)'
    }
  },
  MuiInputBase: {
    root: {
      minHeight: '40px',
      borderColor: '#DCDEE0'
    },
    formControl: {
      fontWeight: 400
    },
    input: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.4,

      '&::placeholder': {
        fontWeight: '400 !important'
      }
    },
    multiline: {
      height: 'auto'
    }
  },
  MuiInputLabel: {
    root: {
      fontWeight: '400 !important',
      fontSize: '0.875rem',
      lineHeight: 1.2
      // color: '#687077', TODO: update from palette when design will be ready (incl dark theme)
    },
    asterisk: {
      color: '#01B18D' // TODO: update from palette when design will be ready (incl dark theme)
    }
  }
}
