export const parse = (
  string,
  { separator = /\s/, ignoreEmptyValues = true, trimValues = true } = {}
) => {
  let result = string.split(separator).map((s) => s.trim())

  if (trimValues) result = result.map((s) => s.trim())
  if (ignoreEmptyValues) result = result.filter((s) => !!s)

  return result
}

export const stringify = (array, { separator = '\r\n' } = {}) => array.join(separator)

const utils = {
  parse,
  stringify
}

export default utils
